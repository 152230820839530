import styled from 'styled-components';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

export const Container = styled(ToggleGroup.Root)`
  display: inline-flex;

  &[data-orientation='vertical'] {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Items = styled(ToggleGroup.Item)`
  font-family: 'Inter';
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(203, 206, 215, 0.06) 165.52%
    ),
    #f8f9fa;

  padding: 0.5rem 0.75rem;
  color: ${({ theme }) => theme.colors.neutral.black[600]};
  border: solid 1px ${({ theme }) => theme.colors.neutral.black[300]};
  display: flex;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  height: 32px;

  > div > * {
    display: flex;
    align-items: center;
    width: 16px;
    height: 16px;
    justify-content: center;
  }

  & svg {
    path {
      stroke: ${({ theme }) => theme.colors.neutral.black[600]};
    }
  }

  :first-child {
    border-radius: 0.25rem 0 0 0.25rem;
  }

  :last-child {
    border-radius: 0 0.25rem 0.25rem 0;
  }

  &[data-orientation='vertical'] {
    width: 100%;
    border: solid 1.5px ${({ theme }) => theme.colors.borders.default};

    :first-child {
      border-top: solid 1.5px ${({ theme }) => theme.colors.borders.default};

      &[data-state='on'] {
        border-top: solid 1.5px ${({ theme }) => theme.colors.borders.active};
      }
      border-radius: 0.25rem 0.25rem 0 0;
    }

    :last-child {
      border-radius: 0 0 0.25rem 0.25rem;
    }
  }

  &[data-state='off'] {
    :hover {
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 34, 29, 0.2) 165.52%
        ),
        #f8f9fa;
    }
  }
  &[data-state='on'] {
    background: linear-gradient(
        180deg,
        rgba(68, 124, 134, 0.79) 0%,
        #447c86 100%
      ),
      #f8f9fa;
    border-color: ${({ theme }) => theme.colors.accent.secondary[800]};
    color: ${({ theme }) => theme.colors.neutral.white};

    & svg {
      path {
        stroke: ${({ theme }) => theme.colors.neutral.white};
      }
    }
  }
`;
