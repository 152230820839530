import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import ArrowDownIcon from '../../../../assets/icons/arrow-down.svg';
import { LinkGroup } from '../types';
import * as S from '../styles';
import { getExpandInitial } from '../actions';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { light as theme } from 'themes/light';

type GroupLinkComponentProps = {
  linkGroup: LinkGroup[];
  collapse: boolean;
  setCollapse: (collapse: boolean) => void;
};

const GroupLink = ({
  linkGroup,
  collapse,
  setCollapse,
}: GroupLinkComponentProps): ReactElement => {
  type ExpandType = {
    [key: string]: boolean;
  };

  const expandInitial = getExpandInitial(linkGroup);

  const groupLinksRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(groupLinksRef, () => collapse && setExpand(expandInitial));

  const [expand, setExpand] = useState<ExpandType>(expandInitial);

  useEffect(() => {
    if (expand) {
      localStorage.setItem('sidebar-expand-initial', JSON.stringify(expand));
    }
  }, [expand]);

  useEffect(() => {
    if (collapse === true) setExpand({});
  }, [collapse]);

  return (
    <div>
      {linkGroup?.map((group: LinkGroup, index) => (
        <S.Section collapsed={collapse} key={group.title + `${index}`}>
          <S.SectionHeader
            onClick={() =>
              setExpand({ ...expand, [group.title]: !expand[group.title] })
            }
          >
            <span>
              {group.icon && (
                <S.HelpIconWrapper>
                  <ReactSVG src={group.icon} />
                </S.HelpIconWrapper>
              )}
              {!collapse && (
                <>
                  {group.title}

                  {group.tag ? (
                    <S.Tag
                      style={{
                        marginLeft: 16,
                      }}
                      right={38}
                      color={group.tag === 'Alpha' ? 'success' : 'warning'}
                    >
                      {group.tag}
                    </S.Tag>
                  ) : null}
                </>
              )}
            </span>
            <S.ButtonIcon expand={expand[group.title]} collapsed={collapse}>
              <ReactSVG src={ArrowDownIcon} />
            </S.ButtonIcon>
          </S.SectionHeader>

          {expand[group.title] === true && (
            <S.WrapperLinks ref={groupLinksRef}>
              <S.SectionLinks offsetTab={collapse && expand[group.title]}>
                {group.links.map(
                  (link, index) =>
                    !link.hide && (
                      <S.SectionLinkWrapper key={link.name + `${index}`}>
                        <S.SectionLink
                          onClick={() => {
                            const breakpoint = Number(
                              theme.breakpoints.md.replace('px', '')
                            );
                            const isMobileSize = window.innerWidth < breakpoint;
                            if (isMobileSize) {
                              setCollapse(true);
                            }
                            link.action?.();
                          }}
                          active={link.active}
                        >
                          <S.SectionLinkButton onClick={link.action}>
                            {link.name}
                          </S.SectionLinkButton>

                          {link.tag ? (
                            <S.Tag
                              color={
                                link.tag === 'Alpha' ? 'success' : 'warning'
                              }
                            >
                              {link.tag}
                            </S.Tag>
                          ) : null}
                        </S.SectionLink>
                      </S.SectionLinkWrapper>
                    )
                )}
              </S.SectionLinks>
            </S.WrapperLinks>
          )}
        </S.Section>
      ))}
    </div>
  );
};

export default GroupLink;
