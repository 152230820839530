import styled, { css } from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

export const Container = styled(Popover.Root)`
  z-index: 9;
`;

export const Portal = styled(Popover.Portal)``;

export const Content = styled(Popover.Content)<{
  fullWidthContent?: boolean;
  noWrap?: boolean;
}>`
  transition: all 0.2s ease-in-out;
  z-index: 15 !important;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 0.5rem;
  border-radius: 8px;
  will-change: transform, opacity;
  display: block;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);

  ${({ noWrap }) =>
    noWrap &&
    css`
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    `}

  ${({ fullWidthContent }) =>
    fullWidthContent &&
    css`
      width: 100%;
    `}
`;

export const Trigger = styled(Popover.Trigger)<{
  hasborder?: boolean;
  hasFocus?: boolean;
  triggerFullWidth?: boolean;
}>`
  background-color: transparent;
  cursor: pointer;

  ${({ triggerFullWidth }) =>
    triggerFullWidth &&
    css`
      width: 100%;
    `}

  ${({ className }) =>
    !className?.includes('no-style') &&
    css`
      transition: all 0.2s ease-in-out;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 4px;

      :focus {
        border: 1px solid #e6eafd;
      }

      &[data-state='open'] {
        border: 1px solid #e6eafd;
      }
    `}

  ${({ hasFocus }) =>
    hasFocus &&
    css`
      border: none;

      :focus {
        border: none;
      }

      &[data-state='open'] {
        border: none;
      }
    `}
`;
