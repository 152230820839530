import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
`;

export const WrapperPills = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.sm}px;
`;

export const Pill = styled.div<{ className?: string }>`
  display: flex;
  align-items: center;
  line-height: 28px;
  height: 32px;
  padding: 0 ${({ theme }) => theme.spacings.md}px;
  background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  color: ${({ theme }) => theme.colors.neutral.black[700]};
  max-width: 458px;
  border: solid 1px ${({ theme }) => theme.colors.borders.default};
  border-radius: 12px;
  font-weight: 500;
  gap: ${({ theme }) => theme.spacings.sm}px;

  :hover {
    border: solid 1px ${({ theme }) => theme.colors.borders.hover};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
  }

  ${({ className }) =>
    className?.includes('dropdown') &&
    css`
      width: auto;
      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-left: ${({ theme }) => theme.spacings.sm}px;
      }
      margin-left: ${({ theme }) => theme.spacings.sm}px;
      font-weight: 600;

      > svg {
        margin-left: ${({ theme }) => theme.spacings.sm}px;
      }
    `}
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  max-width: 120px;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacings.sm}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: auto;
  }
`;

export const WrapperDropDown = styled.span`
  display: flex;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const Label = styled.span`
  font-weight: 600;
  white-space: nowrap;
  padding-right: ${({ theme }) => theme.spacings.xs}px;
`;

export const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ClearButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid transparent;

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[300]};
    border: 1px solid ${({ theme }) => theme.colors.borders.active};
  }

  :focus {
    background-color: ${({ theme }) => theme.colors.neutral.black[400]};
    border: 1px solid ${({ theme }) => theme.colors.borders.active};
  }

  & div {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & svg {
    path {
      fill: ${({ theme }) => theme.colors.text.secondary};
    }
  }
`;

export const DropdownList = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 270px;
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  height: 33px;
  font-weight: 0.875rem;
  color: ${({ theme }) => theme.colors.text.primary};
  padding: ${({ theme }) => theme.spacings.sm}px
    ${({ theme }) => theme.spacings.md}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid transparent;
  border-radius: 4px;

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
    border: 1px solid ${({ theme }) => theme.colors.borders.active};
  }
`;

export const ClearAllFiltersButton = styled.button`
  font-family: 'Inter';
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-weight: 500;
  font-size: 1rem;
  line-height: 28px;
  text-decoration-line: underline;
  font-style: normal;
  padding: ${({ theme }) => theme.spacings.sm}px
    ${({ theme }) => theme.spacings.md}px;
  color: ${({ theme }) => theme.colors.text.primary};
  white-space: nowrap;
`;
