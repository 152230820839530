import styled from 'styled-components';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

export const TouchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  :hover {
    opacity: 0.8;
  }
  cursor: pointer;
`;

export const Wrapper = styled(CheckboxPrimitive.Root)`
  all: unset;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.accent.primary[200] : 'transparent'};
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid
    ${({ theme, checked }) =>
      checked ? 'transparent' : theme.colors.accent.secondary[800]};
`;

export const Indicator = styled(CheckboxPrimitive.Indicator)`
  color: violet;
`;

export const Container = styled.div`
  display: flex;
`;

export const Label = styled.label`
  color: white;
  font-size: 0.9375rem;
  line-height: ${({ theme }) => theme.spacings.sm}px;
  user-select: none;
`;

export const UndeterminedIndicator = styled.div`
  height: 2px;
  width: 10px;
  background-color: ${({ theme }) => theme.colors.neutral.white}; ;
`;
