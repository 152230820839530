import { ReactElement, useCallback, useEffect, useState } from 'react';
import { SwitchProps } from './types';
import * as S from './styles';

const Switch = (props: SwitchProps): ReactElement => {
  const { onChange, name = '', testid } = props;
  const [checked, setChecked] = useState(props.checked);

  const onClickSwitch = useCallback(() => {
    if (onChange) {
      onChange(!checked);
    } else {
      setChecked(!checked);
    }
  }, [onChange, setChecked, checked]);

  useEffect(() => {
    if (typeof props.checked === 'boolean') setChecked(props.checked);
  }, [props.checked, setChecked]);

  return (
    <S.SwitchRoot
      data-testid={testid || `switch-container-${name}`}
      onClick={onClickSwitch}
      checked={checked}
      name={name}
    >
      <S.SwitchThumb />
    </S.SwitchRoot>
  );
};

export default Switch;
