import React, { ReactElement, useState } from 'react';
import * as T from './types';
import * as S from './styles';
import {
  formatLabelTextAccordingItsType,
  handleMaskCurrencyUSD,
} from './actions';

const Slider = (props: T.SliderProps): ReactElement => {
  const {
    min = 1,
    max = 100,
    hasText,
    defaultValue,
    ariaLabel,
    step,
    isRange = false,
    minStepsBetween = 1,
    textType = 'number',
    editable = false,
    onChange,
  } = props;

  const [value, setValue] = useState<number[]>(defaultValue || [0]);

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement>,
    valueIndex: number
  ): void {
    const { value: eventValue } = event.target;

    const limitedValue = Number(eventValue) > max ? max : eventValue;

    if (!isRange) {
      setValue([Number(limitedValue)]);
      return;
    }

    if (valueIndex === 0) setValue([Number(limitedValue), value[1]]);
    if (valueIndex === 1) setValue([value[0], Number(limitedValue)]);
  }

  return (
    <S.Container>
      {hasText && (
        <S.WrapperText>
          <S.Text>{formatLabelTextAccordingItsType(value, textType)}</S.Text>
        </S.WrapperText>
      )}
      <S.SliderRoot
        minStepsBetweenThumbs={minStepsBetween}
        onValueChange={(value) => {
          setValue(value);
          onChange && onChange(value);
        }}
        value={value}
        defaultValue={defaultValue}
        aria-label={ariaLabel}
        step={step || 1}
        min={min}
        max={max}
      >
        <S.Track className={isRange ? 'range' : ''}>
          <S.Range />
        </S.Track>

        {isRange && <S.Thumb />}
        <S.Thumb />
      </S.SliderRoot>

      {editable && (
        <S.WrapperInputs className={!isRange ? 'single-input' : ''}>
          <S.InputSlider
            width={value[0] && value[0].toString().length * 10}
            type="text"
            value={
              textType === 'currency'
                ? handleMaskCurrencyUSD(value[0], max)
                : value[0]
            }
            onChange={(e) => handleInputChange(e, 0)}
          />

          {isRange && (
            <S.InputSlider
              width={value[1] && value[1].toString().length * 10}
              type="text"
              value={
                textType === 'currency'
                  ? handleMaskCurrencyUSD(value[1], max)
                  : value[1]
              }
              onChange={(e) => handleInputChange(e, 1)}
            />
          )}
        </S.WrapperInputs>
      )}
    </S.Container>
  );
};

export default Slider;
