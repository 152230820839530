import { createContext } from 'react';
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { DisplayedUserInfo, EditModeProps, FormProps, IUser } from './types';

type ProfileContextProps = {
  user: IUser;
  editMode: EditModeProps;
  setEditMode: (val: EditModeProps) => void;
  register: UseFormRegister<FormProps>;
  errors: FieldErrorsImpl<DeepRequired<FormProps>>;
  setValue: UseFormSetValue<FormProps>;
  loading: boolean;
  displayInfo?: DisplayedUserInfo;
};

export const ProfileContext = createContext({} as ProfileContextProps);
