import styled, { css } from 'styled-components';
import * as HoverCard from '@radix-ui/react-hover-card';

interface HoverCardStyledProps {
  width: string;
  padding: string;
}

export const Container = styled(HoverCard.Root)``;

export const Content = styled(HoverCard.Content)<HoverCardStyledProps>`
  max-width: 248px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  box-sizing: border-box;
  box-shadow: 1px 8px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  ${({ padding }) => padding && `padding: ${padding}`};

  ${({ width }) =>
    width === '100%' &&
    css`
      width: 100%;
      max-width: unset;
    `};
`;

export const Trigger = styled(HoverCard.Trigger)`
  cursor: 'pointer';
`;

export const Text = styled.div`
  display: 'inline-block';
  margin-left: 10;
`;
