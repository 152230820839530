import styled, { css } from 'styled-components';

interface SideBarStyledProps {
  collapse: boolean;
}

interface ButtonIconStyledProps {
  active?: boolean;
  expand?: boolean;
  isPinnedButton?: boolean;
  collapsed?: boolean;
}

export const Container = styled.div<SideBarStyledProps>`
  z-index: ${({ theme }) => theme.layerindex.sidebar};
  background: ${({ theme }) => theme.colors.neutral.white};
  display: flex;
  flex-direction: column;
  max-width: 224px;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  border-right: 1px solid ${({ theme }) => theme.colors.neutral.black[200]};
  position: sticky;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    ${({ collapse }) =>
      collapse &&
      css`
        max-width: 87px;
      `}
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    z-index: ${({ theme }) => theme.layerindex.drawer};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    max-width: 360px;

    ${({ collapse }) =>
      collapse &&
      css`
        left: -390px;
      `}
  }
`;

export const MainLinkWrapper = styled.div`
  overflow-y: hidden;
  padding: 12px 8px;
  padding-top: 0px !important;
`;

export const Header = styled.div<SideBarStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 0px;
  padding-bottom: 0px !important;
  overflow-x: hidden;
  > a > div > svg {
    margin-right: 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: ${({ theme }) => theme.spacings.md}px;
  }

  padding-bottom: ${({ theme }) => theme.spacings.sm}px;
`;

export const WrapperLogo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  & img {
    margin-left: ${({ theme }) => theme.spacings.md}px;
    margin-bottom: ${({ theme }) => theme.spacings.sm}px;
    max-width: 140px;
  }

  ${({ className }) =>
    className?.includes('mobile') &&
    css`
      & img {
        margin-left: ${({ theme }) => theme.spacings.sm}px;
        margin-bottom: ${({ theme }) => theme.spacings.sm}px;
        max-width: 32px;
      }
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    & img {
      margin-left: ${({ theme }) => theme.spacings.sm}px;
      max-width: 50px;
    }
  }
`;

export const WrapperMenuMobile = styled.div`
  cursor: pointer;
  margin-right: 8px;

  > svg {
    width: 32px;
    height: 32px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const MenuLink = styled.div<{ active?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  height: 36px;
  padding: ${({ theme }) => theme.spacings.sm}px 0px;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 16px;
  border-radius: 4px;
  margin-bottom: ${({ theme }) => theme.spacings.xs}px;
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  cursor: pointer;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: auto;
  }
  padding-left: 12px;

  ${({ active }) =>
    active &&
    css`
      ::after {
        content: '';
        position: absolute;
        left: 0;
        background-color: ${({ theme }) => theme.colors.accent.secondary[800]};
        height: 60%;
        border-radius: 12px;
        width: 4px;
      }
      background-color: ${({ theme }) => theme.colors.neutral.black[200]};
      & svg {
        path {
          fill: ${({ theme }) => theme.colors.accent.secondary[800]};
        }
      }
    `}

  & svg {
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.spacings.md}px;
  }

  > div > div {
    display: flex;
    align-items: center;
  }
`;

export const ProfileMenuLink = styled.div<{ active?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  height: 36px;
  margin-right: 16px;
  padding: ${({ theme }) => theme.spacings.sm}px 0px;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 16px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  cursor: pointer;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;

  & img {
    border-radius: 50%;
    object-fit: cover;
    width: 36px;
    height: 36px;
    margin: 0px 0px 0px 0px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: auto;
    display: none;
  }
  padding-left: 12px;

  ${({ active }) =>
    active &&
    css`
      ::after {
        content: '';
        position: absolute;
        left: 0;
        background-color: ${({ theme }) => theme.colors.accent.secondary[800]};
        height: 60%;
        border-radius: 12px;
        width: 4px;
      }
      background-color: ${({ theme }) => theme.colors.neutral.black[200]};
      & svg {
        path {
          fill: ${({ theme }) => theme.colors.accent.secondary[800]};
        }
      }
    `}

  & svg {
    width: 36px;
    height: 36px;
  }
`;

export const MobileRow = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
  }
`;

export const ProfileMenuMobileLink = styled.div<{ active?: boolean }>`
  display: none;
  position: relative;
  align-items: center;
  height: 36px;
  margin-right: 16px;
  padding: ${({ theme }) => theme.spacings.sm}px 0px;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 16px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  cursor: pointer;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: auto;
    display: flex;
    margin-right: 8px;
    padding: 0px;
  }
  padding-left: 12px;
  & img {
    border-radius: 50%;
    object-fit: cover;
    width: 36px;
    height: 36px;
    margin: 0px 0px 0px 0px;
  }
  ${({ active }) =>
    active &&
    css`
      ::after {
        content: '';
        position: absolute;
        left: 0;
        background-color: ${({ theme }) => theme.colors.accent.secondary[800]};
        height: 60%;
        border-radius: 12px;
        width: 4px;
      }
      background-color: ${({ theme }) => theme.colors.neutral.black[200]};
      & svg {
        path {
          fill: ${({ theme }) => theme.colors.accent.secondary[800]};
        }
      }
    `}

  & svg {
    width: 36px;
    height: 36px;
  }
`;

export const ProfileCogWrapper = styled.div`
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.neutral.black[200]};
  position: absolute;
  bottom: 0px;
  right: 0px;

  & svg {
    width: 12px;
    height: 12px;
  }
`;
export const WrapperMenuLinkMobile = styled.div`
  & svg {
    margin-right: 8px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const Content = styled.div<SideBarStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  transition: all 0.3s ease-in-out;
  padding: 4px 0px;
`;

export const Section = styled.div<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.black[200]};
  transition: all 0.3s ease-in-out;
  padding: 0px 12px;
`;

export const SectionHeader = styled.div`
  cursor: pointer;
  font-weight: 700;
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 17px;
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  padding-left: 12px;
  width: 100%;
  white-space: nowrap;
  > span {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    > svg {
      margin-right: ${({ theme }) => theme.spacings.sm}px;
    }
  }
`;

export const ProfileText = styled.span`
  color: ${({ theme }) => theme.colors.accent.primary[600]};
  margin-left: ${({ theme }) => theme.spacings.md}px;
  font-weight: 600;
  white-space: nowrap;
`;

export const SectionLinkButton = styled.button`
  outline: none;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.text.secondary};
  cursor: pointer;

  :focus {
    color: ${({ theme }) => theme.colors.text.primary};
    text-decoration-line: underline;
  }
`;

export const WrapperLinks = styled.div`
  position: relative;
  z-index: 1;
  margin-left: 24px;
`;

export const CompanySelectionWrapper = styled.div`
  position: absolute;
  border: 1px solid ${({ theme }) => theme.colors.neutral.black[200]};
  top: 96px;
  z-index: 2;
  box-shadow: 1px 8px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background-color: white;
  padding: 4px;
  max-height: 70%;
  overflow-y: auto;
  user-select: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    top: 104px;
  }
`;

export const ProfileOptionsWrapper = styled.div`
  position: absolute;
  border: 1px solid ${({ theme }) => theme.colors.neutral.black[200]};
  top: 60px;
  left: 80%;
  z-index: 99;
  box-shadow: 1px 8px 8px rgba(0, 0, 0, 0.08);
  width: 195px;
  border-radius: 4px;
  background-color: white;
  padding: 4px;
  max-height: 70%;
  overflow-y: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    top: 111px;
    right: 0;
    left: unset;
  }
`;

export const CompanySelectorTitleWrapper = styled.div`
  padding: 4px 8px;
  border: 1px solid transparent;
`;

export const OptionsModalTitleWrapper = styled.div`
  padding: 4px 8px;
  border-left: 1px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.black[200]};
`;

export const LogoutOptionWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.black[200]};
  p {
    color: ${({ theme }) => theme.colors.status.danger};
  }
`;

export const CompanySelectionCompanyRow = styled.div<{ current: boolean }>`
  padding-left: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow-x: hidden;
  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
    border: 1px solid ${({ theme }) => theme.colors.borders.hover};
  }
  :active {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
    border: 1px solid ${({ theme }) => theme.colors.borders.active};
  }
  ${({ current }) =>
    current &&
    css`
      background-color: ${({ theme }) => theme.colors.neutral.black[200]};
      :hover {
        background-color: ${({ theme }) => theme.colors.neutral.black[200]};
      }
    `}
`;

export const OptionsModalRow = styled.div<{ current: boolean }>`
  padding: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow-x: hidden;
  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
    border: 1px solid ${({ theme }) => theme.colors.borders.hover};
  }
  :active {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
    border: 1px solid ${({ theme }) => theme.colors.borders.active};
  }
  ${({ current }) =>
    current &&
    css`
      background-color: ${({ theme }) => theme.colors.neutral.black[200]};
      :hover {
        background-color: ${({ theme }) => theme.colors.neutral.black[200]};
      }
    `}
`;

export const CompanySelectionExpandWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.black[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;

  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.buttons.hover};
  }
`;

export const SectionLinks = styled.ul<{
  offsetTab?: boolean;
  offsetAlign?: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  z-index: ${({ theme }) => theme.layerindex.sidebar};

  ${({ offsetTab, offsetAlign }) =>
    offsetTab &&
    css`
      padding: 8px;
      position: absolute;
      top: ${(offsetAlign === 'bottom' && '-135px') || '-41px'};
      left: 113%;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      width: 224px;
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.colors.borders.default};
    `}

  & a {
    color: ${({ theme }) => theme.colors.text.secondary};

    :focus {
      text-decoration-line: underline;

      & li {
        background-color: ${({ theme }) => theme.colors.neutral.black[200]};
        color: ${({ theme }) => theme.colors.text.primary};
        border: 1px solid ${({ theme }) => theme.colors.borders.hover};
      }
    }
  }
`;

export const SectionLinkWrapper = styled.div`
  cursor: pointer !important;
  .button-icon {
    display: none !important;
  }
  :hover {
    .button-icon {
      display: flex !important;
      opacity: 0.3;
    }
  }
`;

export const SectionLink = styled.li<{ active?: boolean; height?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-left: 12px;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.sm};
  border-radius: 4px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text.secondary};
  border: 1px 0px solid transparent;
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  ${({ height = 32 }) => css`
    height: ${height}px;
  `}

  > svg {
    display: none;
  }

  :hover {
    & a {
      color: ${({ theme }) => theme.colors.text.primary};
    }

    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
    text-decoration-line: underline;

    > svg {
      display: block;
    }
  }

  ${({ active }) =>
    active &&
    css`
      ::after {
        content: '';
        position: absolute;
        left: 0;
        background-color: ${({ theme }) => theme.colors.accent.secondary[800]};
        height: 80%;
        border-radius: 12px;
        width: 4px;
      }
      background-color: ${({ theme }) => theme.colors.neutral.black[200]};
    `}
`;

export const ButtonIcon = styled.button<ButtonIconStyledProps>`
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: white;
  transition: all 0.3s ease-in-out;
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.borders.hover};
    background-color: ${({ theme }) => theme.colors.buttons.secondaryHover};
    opacity: 1 !important;
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.borders.active};
    background-color: ${({ theme }) => theme.colors.buttons.secondaryHover};
  }
  > div > div {
    display: flex;
    align-items: center;
  }

  & svg {
    width: 24px;
    height: 24px;
  }

  ${({ active }) =>
    active &&
    css`
      & svg {
        path {
          fill: ${({ theme }) => theme.colors.accent.secondary[700]};
        }
      }
    `}

  & svg {
    transition: transform 0.3s ease-in-out;

    ${({ expand, collapsed }) =>
      collapsed
        ? css`
            transform: rotate(270deg);
          `
        : expand
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: rotate(0deg);
          `}

    :hover {
      ${({ isPinnedButton, active }) =>
        isPinnedButton &&
        active &&
        css`
          transform: rotate(45deg);
        `}
    }
  }

  ${({ active, isPinnedButton }) =>
    !active &&
    isPinnedButton &&
    css`
      opacity: 0;

      :hover {
        opacity: 1;
      }
    `}
`;

export const Footer = styled.div`
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.black[200]};
`;

export const WrapperCollapse = styled.div`
  transition: text 0.3s ease-in-out;
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 17px;
  cursor: pointer;

  & svg {
    width: 24px;
    height: 16px;
  }
  & span {
    cursor: pointer;
    :hover {
      opacity: 0.5;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const HelpIconWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 16px;
  width: 16px;
  margin-right: ${({ theme }) => theme.spacings.sm}px;
  > div {
    display: flex;
    align-items: center;
    margin-right: ${({ theme }) => theme.spacings.xs}px;
  }

  > div > div {
    display: flex;
    align-items: center;
  }
`;

export const CollapseText = styled.span<{ collapsed?: boolean }>`
  opacity: 1;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;
  white-space: nowrap;
  ${({ collapsed }) =>
    collapsed &&
    css`
      position: absolute;
      left: -250px;
      opacity: 0;
    `}
`;

export const SwitchButtonWrapper = styled.div`
  margin-bottom: 12px;
  align-self: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
export const MobileSwitchButtonWrapper = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    margin-top: ${({ theme }) => theme.spacings.medium}px;
  }
`;

export const SelectCompanyButton = styled.div`
  padding: 4px 12px 4px 12px;
  width: 100%;
  display: flex;
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  gap: 8px;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.neutral.black[300]};
  background-color: ${({ theme }) => theme.colors.neutral.black[100]};
  align-items: center;
  p {
    color: ${({ theme }) => theme.colors.neutral.black[600]};
    line-height: 15px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const SelectCompanyButtonTitleWrapper = styled.div`
  max-width: '80%';
  overflow: hidden;
`;

export const PinnedLinkButton = styled.span`
  width: 100%;

  :hover {
    text-decoration: underline;
  }
`;

export const Tag = styled.div<{
  color: 'warning' | 'primary' | 'success';
  right?: number;
}>`
  position: relative;
  border-radius: 8px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.accent.primary[100]};
  color: ${({ theme }) => theme.colors.accent.primary[800]};
  padding: 0px 8px;

  ${({ color }) =>
    color === 'warning' &&
    css`
      background-color: #ffeed5;
      color: #db7f0d;
    `}

  ${({ color }) =>
    color === 'success' &&
    css`
      background-color: #dff9ef;
      color: #007a48;
    `}
`;
