import React, { ReactElement } from 'react';
import * as T from './types';
import * as S from './styles';
import { ReactSVG } from 'react-svg';

const Icon = (props: T.IconProps): ReactElement => {
  const { src, width, iconName } = props;

  return (
    <S.Container>
      {iconName ? (
        <span className={`e-icons e-${iconName}`}></span>
      ) : src ? (
        <ReactSVG width={width} src={src} />
      ) : null}
    </S.Container>
  );
};

export default Icon;
