export function readVariantAsTags(variant: string): string {
  switch (variant) {
    case 'heading1':
      return 'h1';
    case 'heading2':
      return 'h2';

    default:
      return 'p';
  }
}

export function convertPixelToRem(pixel: number): string {
  return `${pixel / 16}rem`;
}
