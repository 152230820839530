import styled, { css } from 'styled-components';

interface DrawerStyledProps {
  side?: 'left' | 'right';
  width?: number;
}

export const Container = styled.div`
  display: flex;
  z-index: ${({ theme }) => theme.layerindex.drawer};
`;

export const Drawer = styled.div<DrawerStyledProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  max-width: 383px;
  overflow-y: auto;
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.borders.active};
  }

  ${({ width }) =>
    width &&
    css`
      max-width: ${width}px;
    `}

  width: 100%;

  ${({ side }) =>
    side === 'right' &&
    css`
      right: 0;
    `}

  ${({ side }) =>
    side === 'left' &&
    css`
      left: 0;
    `}

  @media (max-width: 564px) {
    max-width: 100%;
  }

  &[data-state='closed'] {
    visibility: hidden;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: ${({ theme }) => theme.layerindex.overlay};

  &[data-state='closed'] {
    visibility: hidden;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px;
`;

export const Content = styled.div<DrawerStyledProps>`
  padding: 0px 24px 90px 24px;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.text.primary};
  width: 100%;
`;

export const Alert = styled.div`
  background: ${({ theme }) => theme.colors.accent.secondary[100]};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px;
  margin-top: 24px;

  & p {
    color: ${({ theme }) => theme.colors.accent.secondary[700]};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
  }

  max-width: 311px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 100%;
  }
`;

export const WrapperAlert = styled.div`
  display: flex;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;

  @media (min-width: 564px) {
    justify-content: flex-end;
  }
`;

export const ButtonMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (min-width: 564px) {
    display: none;
  }
`;
export const ButtonDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 564px) {
    display: none;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.borders.default};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  position: fixed;
  bottom: 0;
`;

export const ButtonSpacing = styled.div`
  margin-right: 8px;
`;
