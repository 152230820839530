import styled, { css } from 'styled-components';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { AvatarColors } from './types';

const getFontSizeRem = (size: number) => {
  const fontSize = size / 2.9;
  const fontSizeRem = fontSize / 16;

  return `${fontSizeRem}rem`;
};

export const Container = styled.div`
  display: flex;
  gap: 20px;
`;

export const Avatar = styled(AvatarPrimitive.Root)<{
  size?: number;
}>`
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  user-select: none;
  border-radius: 100%;
  position: relative;

  ${({ size = 47 }) =>
    css`
      width: ${size}px;
      height: ${size}px;
    `}
`;

export const AvatarImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const AvatarFallback = styled(AvatarPrimitive.Fallback)<{
  size?: number;
  color?: AvatarColors;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: ${({ theme }) => theme.colors.neutral.white};
  font-weight: 500;
  line-height: 1;

  ${({ size = 47 }) => css`
    font-size: ${getFontSizeRem(size)};
  `}

  ${({ color }) =>
    color === 'secondary' &&
    css`
      background-color: ${({ theme }) => theme.colors.accent.secondary[700]};
    `}

  ${({ color }) =>
    color === 'primary' &&
    css`
      background-color: ${({ theme }) => theme.colors.accent.primary[700]};
    `}

  ${({ color }) =>
    color === 'success' &&
    css`
      background-color: ${({ theme }) => theme.colors.status.success};
    `}
    
  ${({ color }) =>
    color === 'warning' &&
    css`
      background-color: ${({ theme }) => theme.colors.status.warning};
    `}

  ${({ color }) =>
    color === 'danger' &&
    css`
      background-color: ${({ theme }) => theme.colors.status.danger};
    `}
`;
