declare global {
  interface Window {
    OneSignal: any;
  }
}

export default async function runOneSignal(userId: string): Promise<void> {
  window.OneSignal = window.OneSignal || [];
  window.OneSignal.push(function () {
    window.OneSignal.init({
      appId:
        location.host === 'cloud.include.com'
          ? '89b674ca-0811-4fdf-b01e-98a3b9c79008'
          : '97d7d9ce-b3ea-4959-bfa6-d9283bca3dd1',
      notifyButton: {
        enable: true,
      },
      promptOptions: {
        slidedown: {
          enabled: true,
          autoPrompt: true,

          actionMessage:
            "We'd like to show you notifications for the latest news and updates.",
          acceptButtonText: 'Accept',
          cancelButtonText: 'Cancel',
        },
      },
      allowLocalhostAsSecureOrigin: true,
      welcomeNotification: {
        disable: false,
        message: 'You are now subscribed to IncludeGo!',
      },
    });

    window.OneSignal.setExternalUserId(userId.toString());
    console.log('OneSignal initialized!');
  });
}
