import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { UnauthorizedError } from 'domain/errors';
import {
  GetUserRolesPermissionsUseCase,
  UserRolePermissions,
} from 'domain/usecases/get-user-roles-permissions-use-case';

export class RemoteGetUserRolesPermissions
  implements GetUserRolesPermissionsUseCase
{
  constructor(
    private readonly httpGetClient: HttpClient,
    private readonly url: string
  ) {}

  async execute(): Promise<{
    id: string;
    type: 'roles';
    attributes: { permissions: UserRolePermissions[] };
  }[]> {
    const response = await this.httpGetClient.request({
      url: this.url,
      method: 'GET',
    });

    const roles = response.body.data;
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return roles;

      case HttpStatusCode.unauthorized:
        throw new UnauthorizedError('Unauthorized request');

      default:
        throw new Error('Unexpected error');
    }
  }
}
