import styled, { css } from 'styled-components';
import { convertPixelToRem, readVariantAsTags } from './actions';
import { TypographyColors, TypographyTextAlign, VariantProps } from './types';

interface TypographyStyledProps {
  variant?: VariantProps;
  noWrap?: boolean;
  underline?: boolean;
  disabled?: boolean;
  isLink?: boolean;
  fontSize?: number;
  fontWeight?: number;
  color?: TypographyColors;
  textAlign?: TypographyTextAlign;
  lineHeight?: string;
}

export const Container = styled.div`
  display: flex;
`;

export const Typography = styled.p.attrs<TypographyStyledProps>(
  ({ variant = 'paragraph' }) => ({
    as: readVariantAsTags(variant),
  })
)<TypographyStyledProps>`
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  display: inline-block;
  margin: 0px;
  padding: 0px;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: nowrap;
  text-align: ${({ textAlign }) => textAlign};

  ${({ variant }) =>
    variant === 'paragraph' &&
    css`
      font-weight: 500;
      color: ${({ theme }) => theme.colors.text.primary};
    `}

  ${({ variant, color }) =>
    variant === 'body_1' &&
    css`
      font-weight: 400;
      font-size: 1rem;
      color: ${({ theme }) =>
        !color ? theme.colors.text.primary : theme.colors.text[color]};
    `}
  ${({ variant, color }) =>
    variant === 'body_2' &&
    css`
      font-weight: 400;
      font-size: 0.88rem;
      color: ${({ theme }) =>
        !color ? theme.colors.text.primary : theme.colors.text[color]};
    `}

  ${({ variant, color }) =>
    variant === 'secondaryText' &&
    css`
      font-weight: 400;
      font-size: 0.875rem;
      color: ${({ theme }) =>
        !color ? theme.colors.neutral.black[600] : theme.colors.text[color]};
    `}

  ${({ variant }) =>
    variant === 'paragraphSec' &&
    css`
      font-weight: 400;
      line-height: 17px;
      color: ${({ theme }) => theme.colors.neutral.black[500]};
    `}

  ${({ variant }) =>
    variant === 'caption' &&
    css`
      font-weight: 400;
      line-height: 15px;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.neutral.black[500]};
    `}

  ${({ variant }) =>
    variant === 'heading1' &&
    css`
      font-weight: 700;
      color: ${({ theme }) => theme.colors.text.title};
      font-size: 2rem;
      line-height: 39px;
    `}

  ${({ variant }) =>
    variant === 'heading2' &&
    css`
      font-weight: 600;
      color: ${({ theme }) => theme.colors.text.title};
      font-size: 1.5rem;
      line-height: 29px;
    `}

  ${({ noWrap }) =>
    noWrap === true &&
    css`
      overflow: unset;
      text-overflow: unset;
      white-space: unset;
    `}

    ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}
    
    ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.text.disabled};
    `}

    ${({ isLink }) =>
    isLink &&
    css`
      color: ${({ theme }) => theme.colors.text.link};
    `}

    ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${convertPixelToRem(fontSize)};
    `}

    ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight};
    `}

    ${({ color }) =>
    color &&
    css`
      color: ${({ theme }) => theme.colors.text[color]};
    `}
    
    ${({ color }) =>
    color === 'secondaryAccent' &&
    css`
      color: ${({ theme }) => theme.colors.accent.secondary[700]};
    `}
    ${({ color }) =>
    color === 'transparent' &&
    css`
      color: transparent;
    `}
    line-height: ${({ lineHeight }) => lineHeight};
`;
