import { RemoteListCompanies } from 'data/usecases';
import { ListCompanyUseCase } from 'domain/usecases';
import {
  makeAxiosHttpClient,
  makeAdminApiUrl,
  makePaymentsApiUrl,
} from 'main/factories/http';
import { getAuthToken } from 'main/helpers/get-auth-token';

export const makeListCompaniesUseCase = (): ListCompanyUseCase => {
  const urlAdminCompanies = makeAdminApiUrl(`/companies?include=tenants`);
  const urlPaymentsCompanies = makePaymentsApiUrl(`/companies?include=tenants`);

  const accessToken = getAuthToken();
  const axios = makeAxiosHttpClient(accessToken);

  return new RemoteListCompanies(
    axios,
    urlAdminCompanies,
    urlPaymentsCompanies
  );
};
