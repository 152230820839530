import styled, { css } from 'styled-components';

interface TableHeadingStyledProps {
  align?: 'left' | 'right' | 'center';
  width?: number;
  hasBulkActions?: boolean;
  hiddenOnMobile?: boolean;
  canDrag?: boolean;
  TableHeading?: boolean;
}
interface TableRowStyledProps {
  selected?: boolean;
  compact?: boolean;
  isHeadingRow?: boolean;
}

export const Container = styled.div<{ fullWidth?: boolean }>`
  position: relative;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  overflow-x: auto;
`;

export const Thead = styled.thead`
  position: relative;
  font-family: 'Inter', sans-serif;
`;

export const WrapperIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacings.xxxl}px;

  & span {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${({ theme }) => theme.colors.neutral.black[500]};
  }
`;

export const TableHeading = styled.th<TableHeadingStyledProps>`
  position: relative;
  border-bottom: 2px solid ${({ theme }) => theme.colors.table.theadBorder};
  padding: 8px 4px;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.neutral.black[600]};
  ${({ width }) => width && `width: ${width}%`};

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    ${({ hiddenOnMobile }) => hiddenOnMobile && `display: none`}
  }

  ${({ canDrag }) =>
    canDrag &&
    css`
      :hover {
        ::after {
          content: ' ';
          position: absolute;
          top: 0;
          right: 0px;
          height: 100%;
          width: 2px;
          background-color: ${({ theme }) => theme.colors.borders.default};
        }
      }
    `}

  ${({ hidden }) => hidden && `display: none`}
`;

export const Dragger = styled.div`
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0;
  width: 10px;
  z-index: 1;

  ${({ draggable }) =>
    draggable &&
    css`
      cursor: col-resize;
    `}
`;

export const CheckBoxTableColumn = styled.td<{ bulkActions?: boolean }>`
  text-align: center;
  padding: 0;
  width: ${({ theme }) => theme.spacings.xxl}px;

  ${({ bulkActions }) =>
    bulkActions &&
    css`
      position: relative;
      left: -8px;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const BulkActions = styled.tr<{ active?: boolean }>`
  display: none;
  z-index: 2;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  border: 1px solid ${({ theme }) => theme.colors.borders.active};
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  padding: 8px;

  ${({ active }) =>
    active &&
    css`
      display: flex;
    `}
`;

export const WrapperActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: -${({ theme }) => theme.spacings.sm}px;

  > p {
    font-weight: 600;
    font-size: 0.875rem;

    line-height: 21px;
  }
`;

export const WrapperPills = styled.div`
  display: flex;
  align-items: center;

  & button {
    margin-left: 8px;
  }
`;

export const TableRow = styled.tr<TableRowStyledProps>`
  border-bottom: solid 1px rgba(124, 124, 124, 0.25);

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.table.selected};

      :hover {
        background-color: ${({ theme }) => theme.colors.table.selected};
      }
    `}

  & a {
    :focus {
      outline: 2px solid ${({ theme }) => theme.colors.accent.secondary[800]};
    }
  }

  :hover {
    ${({ isHeadingRow }) =>
      !isHeadingRow &&
      css`
        background-color: ${({ theme }) => theme.colors.neutral.black[100]};
      `}
  }

  > td {
    height: 71px;
    padding: 10px 4px;

    ${({ compact }) =>
      compact &&
      css`
        height: 48px;
        padding: 0;
      `}
  }

  :active {
    ${({ isHeadingRow }) =>
      !isHeadingRow &&
      css`
        position: relative;
        ::after {
          content: ' ';
          position: absolute;
          top: 10%;
          left: 0;
          height: 80%;
          width: 2px;
          background-color: ${({ theme }) =>
            theme.colors.accent.secondary[700]};
        }
      `}
  }
`;

export const DataColumn = styled.td<{
  hiddenOnMobile?: boolean;
  hidden?: boolean;
  widthMobile?: number;
  align?: 'left' | 'right' | 'center';
}>`
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    ${({ hiddenOnMobile }) => hiddenOnMobile && `display: none`}
    ${({ widthMobile }) => widthMobile && `width: ${widthMobile}%`};
  }

  ${({ hidden }) => hidden && `display: none`}
`;

export const PaginationWrapper = styled.div`
  display: flex;
  background-color: transparent;
  justify-content: flex-end;
  position: sticky;
  margin-top: 27px;
  bottom: 27px;
  margin-right: 64px;
  @keyframes slideIn {
    0% {
      transform: translateX(0) translateY(75%);
    }
    100% {
      transform: translateX(0) translateY(0);
    }
  }
  animation-name: slideIn;
  animation-duration: 0.3s;
  cursor: pointer;
`;

export const PaginationButtonWrapper = styled.div<{ loading: boolean }>`
  opacity: 1;
  button {
    cursor: default !important;
    background-color: #000000;
    border-radius: 21px;
  }
  p {
    color: white;
  }
`;

export const PaginationBottom = styled.div`
  height: 100px;
`;
