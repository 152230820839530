import styled, { css } from 'styled-components';

interface TextareaStyledProps {
  error?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  height?: number;
  hasIcon?: boolean;
  focusColor?: string;
}

export const Container = styled.div<{ fullWidth?: boolean }>`
  max-width: 335px;
  width: 100%;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: 100%;
    `}
`;

export const ErrorMessageText = styled.p`
  margin-top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.status.danger};
`;

export const DescriptionText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: ${({ theme }) => theme.spacings.sm}px;
`;

export const Svg = styled.div`
  padding-left: 5px;
`;

export const TextareaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const Input = styled.textarea<TextareaStyledProps>`
  max-width: 311px;
  background: ${({ theme }) => theme.colors.neutral.white};
  box-shadow: 0 0 0 1px solid ${({ theme }) => theme.colors.borders.default};
  border: 2px transparent solid;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.text.primary};
  padding: ${({ theme }) => theme.spacings.sm}px
    ${({ theme }) => theme.spacings.md}px;
  width: 100%;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.borders.default};

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding: ${({ theme }) => theme.spacings.sm}px
        ${({ theme }) => theme.spacings.xl}px;
    `}

  :focus {
    border: 2px solid ${({ theme }) => theme.colors.borders.active};
    box-shadow: none;
  }

  :hover {
    border: 2px solid ${({ theme }) => theme.colors.borders.hover};
    box-shadow: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.text.disabled};
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: unset;
      width: 100%;
    `}

  ${({ error }) =>
    error &&
    error !== '' &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.status.danger};
      box-shadow: none;

      :focus {
        border-color: ${({ theme }) => theme.colors.status.danger};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.colors.neutral.black[100]};
      border: 2px solid transparent;
      color: ${({ theme }) => theme.colors.text.disabled};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.borders.default};
      cursor: text;

      :hover {
        border: 2px solid ${({ theme }) => theme.colors.neutral.black[300]};
        border: 2px solid transparent;
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.borders.default};
      }
    `}

  ${({ focusColor }) =>
    focusColor &&
    css`
      :focus {
        border-color: ${focusColor};
      }
    `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: ${({ theme }) => theme.spacings.md}px;

  & svg {
    width: 16px;
  }
`;

export const ClearButton = styled.button<{ isFullMode?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: transparent;
  cursor: pointer;
  right: ${({ theme }) => theme.spacings.xl}px;

  :hover {
    & svg {
      path {
        fill: ${({ theme }) => theme.colors.neutral.black[600]};
      }
    }
  }

  ${({ isFullMode }) =>
    isFullMode &&
    css`
      right: ${({ theme }) => theme.spacings.md}px;
    `}
  & svg {
    width: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    right: ${({ theme }) => theme.spacings.md}px;
  }
`;
