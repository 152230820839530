import styled from 'styled-components';
import { Spacings } from './types';

interface SpacerStyledProps {
  height: Spacings;
  heightMobile?: Spacings;
}

export const Spacer = styled.div<SpacerStyledProps>`
  height: ${({ height, theme }) => theme.spacings[height]}px;

  @media (max-width: 768px) {
    height: ${({ heightMobile, theme }) =>
      heightMobile && theme.spacings[heightMobile]}px;
  }
`;
