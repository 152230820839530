/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRoot } from 'react-dom/client';
import Layout from './presentation/layout';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import 'presentation/themes/tailwind.css';

Sentry.init({
  dsn: 'https://b6bee8fab68248fc8669e46cec133264@o4504532209827840.ingest.sentry.io/4504532211531776',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new BrowserTracing({ tracePropagationTargets: [] }),
    new Sentry.Replay({ useCompression: false }),
  ],
});

let agentAttempts = 0;
const agentLoader = setInterval(function () {
  if (agentAttempts > 5) {
    clearInterval(agentLoader);
  } else {
    agentAttempts++;
  }

  // Ensure localStorage has a value before attempting to parse
  const localStorageKey = Object.keys(localStorage).sort()[0];
  if (!localStorageKey) return;

  const storedItem = localStorage[localStorageKey];
  if (!storedItem) return;

  let body;
  try {
    body = JSON.parse(storedItem)?.body;
  } catch (e) {
    console.error('Error parsing JSON from localStorage:', e);
    return;
  }

  const email = body?.decodedToken?.user?.email;
  if (!email) return;

  const companyIdKey = `${email}-companyId`;
  const companyId = localStorage[companyIdKey];
  if (!companyId) return;

  let com;
  try {
    com = JSON.parse(companyId);
  } catch (e) {
    console.error('Error parsing JSON for companyId:', e);
    return;
  }

  const agentIdKey = `agentId-${com}`;
  const agentId = localStorage[agentIdKey];
  if (!agentId) return;

  let agentIdParsed;
  try {
    agentIdParsed = JSON.parse(agentId);
  } catch (e) {
    console.error('Error parsing JSON for agentId:', e);
    return;
  }

  const v = document.createElement('script');
  const s = document.getElementsByTagName('script')[0];
  const api =
    location.hostname === 'cloud.include.com'
      ? 'https://api.include.com'
      : 'https://dev-portal-api.include.com';

  v.onload = function () {
    window['voiceflow'].chat.load({
      verify: { projectID: agentIdParsed },
      url: api + '/includego/',
      versionID: 'production',
      launch: {
        event: {
          type: 'launch',
          payload: {
            goapiurl: api,
            goapikey: body.access_token,
            tenantid: JSON.parse(localStorage[`${email}-tenantId`]),
            companyid: com,
            currentdate: String(new Date().toLocaleDateString()),
          },
        },
      },
    });
  };

  v.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';
  v.type = 'text/javascript';

  s?.parentNode?.insertBefore(v, s);
  clearInterval(agentLoader);
}, 3000);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_DOMAIN || ''}
    clientId={process.env.REACT_APP_CLIENT_ID || ''}
    audience={process.env.REACT_APP_AUDIENCE}
    redirectUri={window.location.origin}
    cacheLocation="localstorage"
  >
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  </Auth0Provider>
);
