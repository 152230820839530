import styled, { css } from 'styled-components';

interface BadgeStyledProps {
  padding?: string;
  colorStyle?: 'due' | 'past-due' | 'paid' | 'void';
}

export const Container = styled.span<BadgeStyledProps>`
  height: 29px;
  text-align: center;
  line-height: 130%;
  font-size: 1rem;
  margin-left: 12px;
  background-color: ${({ theme }) => theme.colors.accent.secondary[200]};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.neutral.black[600]};
  white-space: nowrap;

  ${({ padding }) => padding && `padding: ${padding}`};

  ${({ colorStyle }) =>
    colorStyle === 'past-due' &&
    css`
      background-color: ${({ theme }) => theme.colors.status.warning};
    `}

  ${({ colorStyle }) =>
    colorStyle === 'paid' &&
    css`
      color: ${({ theme }) => theme.colors.neutral.white};
      background-color: ${({ theme }) => theme.colors.status.success};
    `}

  ${({ colorStyle }) =>
    colorStyle === 'void' &&
    css`
      color: ${({ theme }) => theme.colors.neutral.white};
      background-color: ${({ theme }) => theme.colors.neutral.black[600]};
    `}
`;
