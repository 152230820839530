import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: ${({ theme }) => theme.spacings.xl}px
    ${({ theme }) => theme.spacings.xxl}px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 100vw;
    & h2 {
      font-size: large;
      flex-wrap: wrap;
      word-wrap: break-word;
      white-space: normal;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
export const OptionWrapper = styled.div<{ index: number }>`
  cursor: pointer;
  max-width: 50%;
  min-width: 40%;
  flex: 1;
  padding: 24px 12px;
  border: 1px solid transparent;
  border-bottom: 1px solid #e1e2e4;
  margin-right: ${({ theme, index }) => (index % 2 ? 0 : theme.spacings.xl)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  :hover {
    box-shadow: 0px 4px 8px rgba(71, 71, 71, 0.1),
      0px 2px 3px rgba(0, 0, 0, 0.1);
  }
  :active {
    border: 1px solid #696969;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 100%;
  }
`;

export const OptionsListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const OptionImage = styled.img`
  width: 150px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
export const ImagePlaceholder = styled.div`
  width: 150px;
  height: 54px;
  background-color: #f8f9fa;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const ArrowRightIconWrapper = styled.div`
  transform: rotate(270deg) !important;
`;

export const ImageAndTitleWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xl}px;
  align-items: center;
`;
