import styled, { css } from 'styled-components';
import * as Popover from '@radix-ui/react-popover';
import { keyframes } from 'styled-components';

const emergeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%) scale(0.5);
  }
  to {
    opacity: 1;
    transform: translateY(-100%) scale(1);
  }
`;
export const Container = styled(Popover.Root)`
  width: 100%;
`;

export const Portal = styled(Popover.Portal)``;

export const List = styled.div`
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 200px;
  overflow-y: auto;
`;

export const Content = styled(Popover.Content)<{
  width?: number;
}>`
  z-index: 15 !important;
  display: flex;
  border-radius: 4px;
  padding: 4px;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #abacae;
  background: #fff;
  box-shadow: 1px 8px 8px 0px rgba(0, 0, 0, 0.08);
  width: ${({ width }) => width || 200}px;
  min-width: 200px;
  overflow-x: hidden;
`;

export const Item = styled.div<{
  active?: boolean;
}>`
  padding: 8px 16px;
  font-size: 14px;
  color: #212121;
  display: flex;
  flex: 1;
  width: calc(100% - 1px);
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #f5f5f5;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #e1f0ff;
    `}
`;

export const Label = styled.label<{
  active: boolean;
}>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  font-size: 8px;
  font-weight: 400;
  top: 4px;
  line-height: 1;
  padding: 0 4px;
  visibility: hidden;
  transform-origin: center bottom;

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      animation: ${emergeAnimation} 0.17s ease-out forwards;
    `}
`;

export const PopOverTrigger = styled(Popover.Trigger)`
  width: 100%;
`;

export const Trigger = styled.div<{
  fullWidth?: boolean;
  active?: boolean;
}>`
  position: relative;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: #696969;
  padding: 8px 16px;
  min-height: 32px;
  font-weight: 400;
  font-size: 0.875rem;
  border: 1px solid #bababa;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 4px;
  cursor: pointer;
  max-width: 331px;
  width: 100%;
  line-height: 1;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      max-width: none;
    `}

  ${({ active }) =>
    active &&
    css`
      border-color: #696969;
      color: #212121;
    `}
`;

export const TriggerInput = styled.input`
  border: none;
  outline: none;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text.primary};
  flex-grow: 1;
`;

export const TriggerPills = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  background-color: #e1f0ff;
  padding: 2px 4px;
  border-radius: 4px;
  color: #333;
  font-size: 0.75rem;
  white-space: nowrap;
`;

export const TriggerTextWrapper = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
`;
export const ItemText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
