import styled, { css } from 'styled-components';

export const Container = styled.div<{ hiddenOnDesktop?: boolean }>`
  width: 100%;
  padding-left: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    ${({ hiddenOnDesktop }) =>
      hiddenOnDesktop &&
      css`
        display: none;
      `}
  }
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  width: 100%;
  margin-bottom: 21px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
    padding: 8px 16px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }

  > svg {
    position: relative;
    left: 20px;
  }
`;

export const MobileLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const WrapperSearch = styled.div`
  max-width: 362px;
  width: 100%;
  position: relative;

  & div > svg {
    position: absolute;
    top: 13px;
    left: 10px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const InputSearch = styled.input`
  width: 100%;
  height: 44px;
  background: ${({ theme }) => theme.colors.table.striped};
  padding: 11px;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 32px;
`;

export const WrapperTopBarLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: ${({ theme }) => theme.spacings.md}px;
  }
`;

export const TopBarLink = styled.div<{ hiddenMobile?: boolean }>`
  display: flex;
  align-items: center;
  margin: 6px 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;

  & svg {
    margin-right: 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    ${({ hiddenMobile }) =>
      hiddenMobile &&
      css`
        display: none;
      `};
  }
`;
