import styled, { css } from 'styled-components';

export const Container = styled.div<{ fullWidth?: boolean }>`
  width: auto;
  position: relative;
  max-width: 311px;
  width: 100%;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: unset;
      width: 100%;
    `}

  > p {
    margin-top: 0.25rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.status.danger};
  }

  .error {
    border: 1px solid ${({ theme }) => theme.colors.status.danger}!important;

    :focus {
      border-color: ${({ theme }) => theme.colors.status.danger} !important;
    }
  }

  .react-datepicker__time-container--with-today-button {
    right: -86px;
  }
  .react-datepicker {
    border-color: ${({ theme }) => theme.colors.neutral.black[200]};
  }

  .react-datepicker__input-container {
    font-family: 'Inter', sans-serif;

    > input {
      background: ${({ theme }) => theme.colors.neutral.white};
      border: 2px transparent solid;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.188rem;
      color: ${({ theme }) => theme.colors.text.primary};
      height: 35px;
      padding: ${({ theme }) => theme.spacings.sm}px
        ${({ theme }) => theme.spacings.md}px;
      width: 100%;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.borders.default};

      :focus {
        border: 2px solid ${({ theme }) => theme.colors.borders.active};
        box-shadow: none;
      }
    }
  }

  .react-datepicker__header {
    max-width: 311px;
    max-height: 311px;
    color: ${({ theme }) => theme.colors.neutral.black[600]} !important;
    background: ${({ theme }) => theme.colors.neutral.black[200]} !important;
    border-bottom: unset;
  }
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.neutral.black[600]} !important;
  }
  .react-datepicker__day--selected {
    background: ${({ theme }) => theme.colors.accent.primary[600]};
  }

  .react-datepicker-popper .popper-react-date > * {
    font-family: 'Manrope', sans-serif !important;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: ${({ theme }) => theme.colors.accent.primary[600]};
  }
  .react-datepicker__time-container {
    height: 100%;
    background: ${({ theme }) => theme.colors.neutral.white};
  }

  .react-datepicker__time-list {
    height: auto;
  }

  .react-datepicker__today-button {
    background: ${({ theme }) => theme.colors.neutral.black[200]};
    color: ${({ theme }) => theme.colors.neutral.black[600]};
    width: 43px;
    border-radius: 4px;
    font-size: 0.625rem;
    border: none;
    margin-top: 0px;
    float: right;
    margin-right: 4px;
    margin-bottom: 4px;
  }
`;

export const Input = styled.div``;
