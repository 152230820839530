import styled, { css } from 'styled-components';
import { keyframes } from 'styled-components';

const emergeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%) scale(0.5);
  }
  to {
    opacity: 1;
    transform: translateY(-100%) scale(1);
  }
`;

interface InputStyledProps {
  error?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  height?: number;
  hasIcon?: boolean;
  hasClearButton?: boolean;
  focusColor?: string;
}

export const Container = styled.div<{ fullWidth?: boolean }>`
  max-width: 331px;
  width: 100%;
  border-radius: 4px;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: 100%;
    `}
`;

export const ErrorMessageText = styled.p`
  margin-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.063rem;
  color: ${({ theme }) => theme.colors.status.danger};
`;

export const DescriptionText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.063rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: 4px;
`;

export const Svg = styled.div``;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 4px;
  gap: 4px;
`;

export const Label = styled.label<{
  active: boolean;
}>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  font-size: 8px;
  font-weight: 400;
  top: 4px;
  left: 16px;
  z-index: 1;
  line-height: 1;
  padding: 0 4px;
  visibility: hidden;
  transform-origin: center bottom;

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      animation: ${emergeAnimation} 0.17s ease-out forwards;
    `}
`;

export const Input = styled.input<InputStyledProps>`
  width: 100%;
  border: 1px solid #bababa;
  border-radius: 4px;
  height: 32px;
  padding: 8px 16px;
  font-size: 0.875rem;
  color: #212121;

  ${({ error }) =>
    !error &&
    css`
      border-color: ${({ theme }) => theme.colors.neutral.black[400]};

      :hover {
        border-color: #212121;
      }

      :focus {
        border-color: ${({ focusColor }) => focusColor || '#0563D6'};
      }
    `}

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-left: 40px !important;
    `}

  ${({ hasClearButton }) =>
    hasClearButton &&
    css`
      padding-right: 40px !important;
    `}

  ${({ height }) =>
    height &&
    css`
      height: ${height}px !important;
    `}

    ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.status.danger};
    `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  left: ${({ theme }) => theme.spacings.md}px;

  & div {
    display: flex;
    align-items: center;
  }

  & svg {
    width: 16px;
  }
`;

export const WrappedIcon = styled.button<{ isFullMode?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: transparent;
  cursor: pointer;
  right: ${({ theme }) => theme.spacings.md}px;
  z-index: 1;

  :hover {
    & svg {
      path {
        fill: ${({ theme }) => theme.colors.neutral.black[600]};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    right: ${({ theme }) => theme.spacings.md}px;
  }

  & div {
    display: flex;
    align-items: center;
  }
`;
