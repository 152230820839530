import { RefObject, useEffect } from 'react';

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: UIEvent) => void
): void {
  useEffect(() => {
    const listener = (event: any) => {
      const el = ref?.current;

      if (
        event.target.classList.contains('e-list-item') ||
        event.target.classList.contains('e-chips-close') ||
        event.target.classList.contains('e-chipcontent') ||
        event.target.classList.contains('fui-Calendar') ||
        event.target.getAttribute('aria-hidden') ||
        event.target.classList.contains('e-list-item') ||
        event.target?.parentElement?.classList?.contains('rdp-day') ||
        event.target.classList?.contains('rdp-cell') ||
        event.target?.classList?.contains('rdp-button_reset') ||
        event.target?.parentElement?.classList?.contains('rdp-nav') ||
        event.target?.parentElement?.classList?.contains('rdp-button') ||
        event.target.classList?.contains('rdp-nav_icon') ||
        event.target.getAttribute('aria-live') ||
        event.target.classList.contains('clear-button') ||
        event.target.classList.contains('rdp-caption') ||
        event.target.classList.contains('rdp-caption_label') ||
        event.target.classList.contains('rdp') ||
        event.target.classList.contains('daypicker') ||
        event.target.classList.contains('rdp-head_cell') ||
        event.target.classList.contains('rdp-head_row') ||
        event.target.classList.contains('popover-item') ||
        event.target.classList.contains('popover-item-text')
      ) {
        return; // If it does, don't execute the handler
      }

      const isHandleable = el && !el.contains(event.target);
      if (isHandleable) return handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);
    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
