import { getAuthToken } from 'main/helpers/get-auth-token';
import { makeAxiosHttpClient, makeAdminApiUrl } from '../http';
import { RemoteGetUserRolesPermissions } from 'data/usecases/remote-get-user-roles-permissions';
import { GetUserRolesPermissionsUseCase } from 'domain/usecases/get-user-roles-permissions-use-case';

export const makeRemoteGetUserRolesPermissions =
  (): GetUserRolesPermissionsUseCase => {
    const url = makeAdminApiUrl('/roles/token/permissions');
    const token = getAuthToken();
    const httpClient = makeAxiosHttpClient(token);

    return new RemoteGetUserRolesPermissions(httpClient, url);
  };
