import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  makeListCompaniesUseCase,
  makeRemoteGetProfileUseCase,
  makeRemoteLoadSingleCompanyPublic,
} from 'main/factories/usecases';
import { CompanySelector, light, Loading } from 'include-ui';
import styled, { ThemeProvider } from 'styled-components';
import { ICompany } from 'domain/entities/company';
import { makeLocalStorageAdapter } from 'main/factories/cache';
import * as Sentry from '@sentry/react';
import { Division } from 'domain/entities/Division';
import FallBackImage from '../assets/resizedinclude.png';
import { makeRemoteGetUserRolesPermissions } from 'main/factories/usecases/get-user-roles-permissions';
interface SelfContextState {
  tenantId?: string;
  companyId?: string;
  companyName?: string;
  permissions: Set<string>;
  onSelectCompany: (company: { id: string }) => void;
  companies: ICompany[];
  divisions: Division[];
  companySmalllogo?: string;
  companyLargeLogo?: string;
  loadingDivisions?: boolean;
  profilePic?: string;
  setProfilePic: (profilePic?: string) => void;
}

export const SelfContext = React.createContext({} as SelfContextState);

interface SelfContextProviderProps {
  children: ReactElement;
}

type CompanySelectorOptions = {
  id: string;
  title: string;
  imageUrl?: string;
}[];

const LoadingContainer = styled.div({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: '1000 !important',
});

const firstLetterUppercase = (s: string) => {
  const [initial, ...rest] = s.split('');
  return [initial.toUpperCase(), ...rest].join('');
};

export const SelfContextProvider: React.FunctionComponent<
  SelfContextProviderProps
> = ({ children }): ReactElement => {
  const { user } = useAuth0();
  const [tenantId, setTenantId] = useState<string>();
  const [agentId, setAgentId] = useState<string>();
  const [companyId, setCompanyId] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [companies, setCompanies] = useState<ICompany[]>([]);

  const [companySmalllogo, setCompanySmalllogo] = useState<string>();
  const [companyLargeLogo, setCompanyLargeLogo] = useState<string>();

  const [profilePic, setProfilePic] = useState<string>();

  const [shouldSkipCompanySelector, setShouldSkipCompanySelector] =
    useState(false);

  const showCompanySelector = useMemo(
    () => user && (!tenantId || !companyId) && !shouldSkipCompanySelector,
    [user, tenantId, companyId, shouldSkipCompanySelector]
  );
  const [options, setOptions] = useState<CompanySelectorOptions>([]);
  const [nickname, setNickname] = useState('');
  const [loading, setLoading] = useState(true);

  const [loadingDivisions, setLoadingDivisions] = useState(true);
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [rolesPermissions, setRolesPermissions] = useState<string[]>([]);
  const localStorageAdapter = makeLocalStorageAdapter();
  const tenantIdKey = `${user?.email}-tenantId`;
  const companyIdKey = `${user?.email}-companyId`;

  useEffect(() => {
    if (!companyId && !user) return;

    async function fetchPermissions() {
      try {
        setLoading(true);
        const roles = await makeRemoteGetUserRolesPermissions().execute();

        if (roles) {
          setRolesPermissions(
            roles.flatMap((role) => role?.attributes?.permissions) || []
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchPermissions();
  }, [companyId, user]);

  useEffect(() => {
    if (user?.nickname) {
      setNickname(user.nickname);
    }
  }, [user?.nickname]);

  let fetchCompanyTry = 0;

  async function retry(callback: Promise<void>): Promise<void> {
    try {
      await callback;
    } catch (error) {
      console.error(error);
    }
  }

  const setCompanyInformation = useCallback(
    (company: ICompany) => {
      setCompanyId(company.id);
      setCompanyName(company.name);
      setCompanyLargeLogo(company?.large_logo);
      setCompanySmalllogo(company?.small_logo);
      setTenantId(company.tenant_id);
      setAgentId(company.agent_id);
    },
    [
      setCompanyId,
      setCompanyName,
      setCompanyLargeLogo,
      setCompanySmalllogo,
      setTenantId,
      setAgentId,
    ]
  );

  useEffect(() => {
    if (companyId) localStorageAdapter.setItem(companyIdKey, companyId);
    if (tenantId) localStorageAdapter.setItem(tenantIdKey, tenantId);
    if (agentId && companyId)
      localStorageAdapter.setItem(`agentId-${companyId}`, agentId);
  }, [companyId, tenantId, agentId]);

  const fetchCompanies = useCallback(async () => {
    if (!user) return;

    try {
      setLoading(true);

      Sentry.setUser({
        email: user.email,
        username: user.nickname,
      });

      const companies = await makeListCompaniesUseCase().execute();

      setCompanies(companies);

      const options: CompanySelectorOptions = companies.map(
        ({ id, name: title, large_logo }) => ({
          id,
          title,
          imageUrl: large_logo ?? FallBackImage,
        })
      );
      setOptions(options);
      if (companies.length <= 1) {
        if (companies.length === 1) {
          setCompanyInformation(companies[0]);
        }

        setShouldSkipCompanySelector(true);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (fetchCompanyTry < 3) {
        fetchCompanyTry++;
        await retry(fetchCompanies());
      }
    } finally {
      setLoading(false);
    }
  }, [user, setCompanies, setOptions]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    function verifyAndSetCompany() {
      if (!user?.email || !companies) return;

      const newCompanyId = localStorageAdapter.getItem(companyIdKey);
      const newTenantId = localStorageAdapter.getItem(tenantIdKey);

      if (newCompanyId && newTenantId) {
        setShouldSkipCompanySelector(true);

        const company = companies.find(({ id }) => id === newCompanyId);

        if (company) {
          setCompanyInformation(company);
        }
      }
    }

    verifyAndSetCompany();
  }, [user, companyIdKey, tenantIdKey, companies]);

  function onClickOption(company: { id: string }) {
    const companySelected = companies.find(({ id }) => id === company.id);

    if (companySelected) {
      setCompanyInformation(companySelected);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  const permissions = useMemo(() => {
    const permissionsSet = new Set();
    const permissionsArray = user?.['/permissions'] || [];
    [...permissionsArray, ...rolesPermissions].forEach((element) => {
      if (element.includes('when')) {
        permissionsSet.add(element);
      }
    });
    return permissionsSet;
  }, [user, rolesPermissions, companyId]) as Set<string>;

  useEffect(() => {
    if (!companyId) return;

    (async () => {
      try {
        setLoadingDivisions(true);
        const company = await makeRemoteLoadSingleCompanyPublic(
          companyId
        ).execute();

        if (company.divisions) {
          setDivisions(company.divisions);
          setCompanyLargeLogo(company?.large_logo);
          setCompanySmalllogo(company?.small_logo);
        } else {
          setDivisions([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingDivisions(false);
      }
    })();
  }, [companyId, setDivisions]);

  useEffect(() => {
    async function fetchUser() {
      try {
        const data = await makeRemoteGetProfileUseCase().execute();

        setProfilePic(data.picture ?? data.default_picture);
      } catch (error) {
        console.error(error);
      }
    }

    fetchUser();
  }, []);

  return (
    <SelfContext.Provider
      value={{
        loadingDivisions,
        tenantId,
        companyId,
        permissions,
        companyName,
        onSelectCompany: onClickOption,
        companies,
        divisions,
        companySmalllogo,
        companyLargeLogo,
        profilePic,
        setProfilePic,
      }}
    >
      <ThemeProvider theme={light}>
        <>
          {showCompanySelector ? (
            loading ? (
              <LoadingContainer>
                <Loading />
              </LoadingContainer>
            ) : (
              <div>
                <CompanySelector
                  id="core-selector"
                  primaryText={`Hi ${
                    nickname ? firstLetterUppercase(nickname) : ''
                  }`}
                  secondaryText="Please select a company you would like to enter:"
                  options={options}
                  onClickOption={onClickOption}
                />
              </div>
            )
          ) : (
            <>{children}</>
          )}
        </>
      </ThemeProvider>
    </SelfContext.Provider>
  );
};
