import React, { ReactElement } from 'react';
import * as S from '../styles';

type MobileMenuLinksProps = {
  collapse: boolean;
  links: {
    name: string;
    linkTo: string;
  }[];
};

const MobileMenuLinks = ({
  collapse,
  links,
}: MobileMenuLinksProps): ReactElement => {
  return (
    <S.WrapperMenuLinkMobile>
      {links.map((link, index) => (
        <a key={index} href={link.linkTo}>
          <S.MenuLink>{!collapse && link.name}</S.MenuLink>
        </a>
      ))}
    </S.WrapperMenuLinkMobile>
  );
};

export default MobileMenuLinks;
