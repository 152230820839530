import styled, { css } from 'styled-components';
import * as Slider from '@radix-ui/react-slider';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 316px;
  width: 100%;
`;

export const SliderRoot = styled(Slider.Root)`
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  touch-action: none;
  height: 20;
  width: 100%;
`;

export const Track = styled(Slider.Track)<{ className?: string }>`
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.colors.neutral.black[400]};
  height: 4px;

  &:before {
    content: '';
    position: absolute;
    top: -6px;
    right: calc(100% - 16px);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.accent.primary[500]};

    ${({ className }) =>
      className?.includes('range') &&
      css`
        background-color: ${({ theme }) => theme.colors.neutral.black[400]};
      `}
  }

  &:after {
    content: '';
    position: absolute;
    top: -6px;
    left: calc(100% - 16px);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.neutral.black[400]};
  }
`;

export const Range = styled(Slider.Range)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.accent.primary[500]};
  border-radius: 9999px;
  height: 100%;
`;

export const Thumb = styled(Slider.Thumb)`
  all: unset;
  background-color: ${({ theme }) => theme.colors.accent.primary[500]};
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  z-index: 1;

  :focus {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    box-shadow: 0 0 0 4px rgba(8, 121, 144, 0.43);
  }
`;

export const WrapperText = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.lg}px;
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-size: 0.875rem;
  line-height: 17px;
  height: 21px;
  color: ${({ theme }) => theme.colors.text.title};
`;

export const WrapperInputs = styled.div<{ className?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacings.md}px;
  width: 100%;

  ${({ className }) =>
    className?.includes('single-input') &&
    css`
      justify-content: center;
    `}
`;

export const InputSlider = styled.input<{ width?: number }>`
  font-family: 'Inter';
  font-size: 0.875rem;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.text.title};
  height: 21px;
  padding: 2px;
  border: 1px solid transparent;
  box-sizing: border-box;
  text-align: center;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.borders.default};
    background-color: ${({ theme }) => theme.colors.buttons.secondaryHover};
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.borders.active};
    background-color: ${({ theme }) => theme.colors.buttons.secondaryHover};
  }

  ${({ width }) =>
    width &&
    css`
      width: ${Number(width) + 24}px;
    `}
`;
