import styled, { css } from 'styled-components';
import * as Progress from '@radix-ui/react-progress';
import { ProgressProps } from './types';

export const Container = styled(Progress.Root)<{
  height: number;
  backgroundColor?: ProgressProps['backgroundColor'];
}>`
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.neutral.black[400]};
  border-radius: 99999px;
  width: 100%;
  height: ${({ height }) => height}px;
  transform: translateZ(0);

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background: ${backgroundColor};
    `}
`;

export const Indicator = styled(Progress.Indicator)<{
  color: ProgressProps['color'];
}>`
  background: ${({ theme }) => theme.colors.accent.primary[700]};
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);

  ${({ color, theme }) =>
    color === 'red' &&
    css`
      background: ${theme.colors.status.danger};
    `}

  ${({ color, theme }) =>
    color === 'primary' &&
    css`
      background: ${theme.colors.accent.primary[700]};
    `}

  ${({ color }) =>
    color &&
    color.startsWith('#') &&
    css`
      background: ${color};
    `}
`;
