import styled, { css } from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

interface TriggerStyledProps {
  color?: string;
  height?: number;
  selected?: boolean;
  disabled?: boolean;
}

interface ItemStyledProps {
  className?: string;
  padding?: string;
}

export const Content = styled(DropdownMenu.Content)<{ width: number }>`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  margin-top: 5px;
  min-width: ${({ width }) => width}px;
`;

export const CustomTrigger = styled(DropdownMenu.Trigger)`
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 4px;
`;

export const CustomContent = styled.div`
  padding: 0.5rem;
`;

export const SearchWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings.sm}px;

  & input {
    width: 100%;
  }

  width: 100%;
`;

export const WrapperItems = styled.div`
  position: relative;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
`;

export const WrapLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
`;

export const Trigger = styled(DropdownMenu.Trigger)<TriggerStyledProps>`
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 8px 16px;
  height: 32px;
  line-height: 130%;
  font-weight: 400;
  font-size: 0.875rem;
  border: 1px solid #bababa;
  border-radius: 4px;
  cursor: pointer;
  overflow: unset;
  text-overflow: unset;
  white-space: unset;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}

  background: ${({ theme }) => theme.colors.neutral.white};

  & span {
    width: calc(100% - 0.1px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ color }) =>
    !color &&
    css`
      &[data-state='open'] {
        border-color: ${({ theme }) => theme.colors.accent.primary[700]};
        background: ${({ theme }) => theme.colors.neutral.black[100]};
      }

      :hover {
        border-color: #696969;
      }

      :focus {
        border-color: ${({ theme }) => theme.colors.accent.primary[600]};
      }
    `}

  ${({ selected }) =>
    selected &&
    css`
      background-color: #e1e2e4;
    `}

  ${({ height = 32 }) =>
    css`
      min-height: ${height}px;
    `}


  & svg {
    margin-left: 5px;
  }

  & img {
    margin-right: 12px;
  }

  > span {
    display: flex;
    align-items: center;
  }

  ${({ color }) =>
    color !== 'default' &&
    css`
      & svg {
        path {
          fill: #666;
        }
      }
    `}

  ${({ color }) =>
    color === 'blue' &&
    css`
      border: none;
      background-color: #e1f0ff;
      color: #023390;
    `}

  ${({ color }) =>
    color === 'green' &&
    css`
      border: none;
      background-color: #dff9ef;
      color: #007a48;
    `}

  ${({ color }) =>
    color === 'yellow' &&
    css`
      border: none;
      background-color: #ffeed5;
      color: #db7f0d;
    `}

  ${({ color }) =>
    color === 'red' &&
    css`
      border: none;
      background-color: #f7d9db;
      color: #920c17;
    `}

  ${({ color }) =>
    color === 'purple' &&
    css`
      border: none;
      background-color: #e0ccf1;
      color: #400e69;
    `}
`;

export const Item = styled(DropdownMenu.Item)<ItemStyledProps>`
  font-family: 'Inter', sans-serif;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral.black[700]};
  min-height: 33px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;

  ${({ padding = '0 8px;' }) => css`
    padding: ${padding};
  `}

  > img {
    margin-right: 5px;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  }

  :focus {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  }

  :active {
    border: 1px solid ${({ theme }) => theme.colors.borders.active};
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  }

  ${({ className }) =>
    className?.includes('warning') &&
    css`
      color: ${({ theme }) => theme.colors.status.danger};
    `}

  ${({ className }) =>
    className?.includes('hasDescription') &&
    css`
      padding: ${({ theme }) => theme.spacings.xs}px 8px;
    `}
`;

export const ItemLabel = styled.label`
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const ItemDescription = styled.span`
  font-size: 0.875rem;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.text.secondary};
  display: block;
  padding: ${({ theme }) => theme.spacings.xs}px 0;
`;

export const ClearButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings.sm}px;

  > div {
    margin-right: ${({ theme }) => theme.spacings.sm}px;
  }
  & button {
    color: ${({ theme }) => theme.colors.accent.primary[600]};
  }
`;

export const IconWrapper = styled.div`
  & div {
    display: flex;
    align-items: center;
  }
`;
