interface User {
  sub?: string;
}

export function getAccountId(user?: User): string {
  if (!user) return '';
  function auth0guid(val) {
    val = val.split('|');
    val = val[val.length - 1] + "00000000000000000000000000000000";
    return ''
      + val.substring(0, 8) + '-'
      + val.substring(8, 12) + '-'
      + val.substring(12, 16) + '-'
      + val.substring(16, 20) + '-'
      + val.substring(20, 32)
  }
  return auth0guid(user['sub']).toLowerCase() || '';
}
