import styled, { css } from 'styled-components';

interface BreadCrumbItemStyledProps {
  active?: boolean;
  className?: string;
}

export const Container = styled.div<{ hiddenOnMobile: boolean }>`
  display: flex;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    ${({ hiddenOnMobile }) =>
      hiddenOnMobile &&
      css`
        display: none;
      `}
  }
`;

export const BreadCrumb = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const WrapperBackButton = styled.button`
  outline: none;
  border: 1px solid transparent;
  background: none;
  cursor: pointer;
  border-radius: 5px;

  > div > div {
    display: flex;
    align-items: center;
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.borders.hover};
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 0 ${({ theme }) => theme.spacings.sm}px;

  > svg {
    margin: 0 6px;
  }
`;

export const Link = styled.button<BreadCrumbItemStyledProps>`
  outline: none;
  background: transparent;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 8px;
  border-radius: 9.5px;
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};

  ${({ className }) =>
    className?.includes('hasBackground') &&
    css`
      background-color: ${({ theme }) => theme.colors.neutral.black[200]};
    `}

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.text.title};
    `}

  :hover {
    color: ${({ theme }) => theme.colors.accent.secondary[700]};
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.borders.hover};
    color: ${({ theme }) => theme.colors.accent.secondary[700]};
  }
`;

export const Separator = styled.span`
  margin: 0 ${({ theme }) => theme.spacings.xs}px;

  color: ${({ theme }) => theme.colors.neutral.black[400]};
`;

export const Title = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;
`;
