import { RemoteGetSingleCompanyUseCase } from 'data/usecases';
import { makeAdminApiUrl, makeAxiosHttpClient } from '../http';

export const makeRemoteLoadSingleCompanyPublic = (
  companyId: string
): RemoteGetSingleCompanyUseCase => {
  const url = makeAdminApiUrl(
    `/public/companies/${encodeURIComponent(companyId)}`
  );

  const httpClient = makeAxiosHttpClient('');
  return new RemoteGetSingleCompanyUseCase(httpClient, url);
};
