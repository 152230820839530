import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.lg}px;
  max-width: 506px;
  width: 100%;
`;

export const InformationCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.md}px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.black[200]};
  padding: ${({ theme }) => theme.spacings.md}px 0;
`;
