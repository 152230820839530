import React, { ReactElement } from 'react';
import * as S from './styles';
import * as T from './types';

const Spacer = (props: T.SpacerProps): ReactElement => {
  const { height, heightMobile } = props;

  return <S.Spacer height={height} heightMobile={heightMobile} />;
};

export default Spacer;
