import { StorageClient } from 'data/protocols/cache/StorageClient';

export class LocalStorageProvider implements StorageClient {
  getItem(item: string): unknown {
    const localItem = localStorage.getItem(item);

    if (localItem) {
      return JSON.parse(localItem);
    }
    return null;
  }

  setItem(key: string, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(item: string): void {
    localStorage.removeItem(item);
  }
}
