import React, { ReactElement } from 'react';
import * as T from './types';
import * as S from './styles';

const Typography = (props: T.TypographyProps): ReactElement => {
  const {
    children,
    variant,
    disabled,
    noWrap,
    underline,
    isLink,
    fontSize,
    fontWeight,
    color,
    textAlign = 'left',
    lineHeight,
    style,
  } = props;

  const styles = {
    variant,
    disabled,
    noWrap,
    underline,
    isLink,
    fontSize,
    fontWeight,
    color,
    textAlign,
    lineHeight,
  };

  return (
    <S.Container style={style}>
      <S.Typography {...styles}>{children}</S.Typography>
    </S.Container>
  );
};

export default Typography;
