import { ReactElement, useCallback } from 'react';
import * as S from './styles';
import IncludeLogo from '../../../assets/images/include-logo-small.png';
import Typography from 'components/common/Typography';
import Spacer from 'components/common/Spacer';
import { CompanySelectorProps, CompanySelectorOption } from './types';
import ArrowDown from '../../../assets/icons/arrow-down.svg';
import Icon from 'components/common/Icon';

const CompanySelector = (props: CompanySelectorProps): ReactElement => {
  const {
    primaryText,
    secondaryText,
    options = [],
    id = 'company-selector',
    onClickOption = () => undefined,
  } = props;

  const onClick = useCallback(
    (option: CompanySelectorOption) => () => {
      onClickOption(option);
    },
    [onClickOption]
  );

  return (
    <S.Container data-testid={id} id={id}>
      <img src={IncludeLogo} />
      <Spacer height="lg" />
      <S.TitleContainer>
        <Typography variant="heading2">{primaryText}</Typography>
        <Spacer height="sm" />
        <Typography variant="heading2">{secondaryText}</Typography>
      </S.TitleContainer>
      <Spacer height="lg" />
      <S.OptionsListWrapper>
        {options.map(({ title, id, imageUrl }, index) => (
          <S.OptionWrapper
            key={id}
            index={index}
            onClick={onClick({ title, id, imageUrl })}
            id={`company-selection-${index}`}
            data-testid={`company-selection-${index}`}
          >
            <S.ImageAndTitleWrapper>
              {imageUrl ? (
                <S.OptionImage src={imageUrl} />
              ) : (
                <S.ImagePlaceholder />
              )}
              <Typography variant="heading2">{title}</Typography>
            </S.ImageAndTitleWrapper>
            <S.ArrowRightIconWrapper>
              <Icon src={ArrowDown} />
            </S.ArrowRightIconWrapper>
          </S.OptionWrapper>
        ))}
      </S.OptionsListWrapper>
    </S.Container>
  );
};

export default CompanySelector;
