import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { ICompany } from 'domain/entities/company';
import { UnauthorizedError, UnexpectedError } from 'domain/errors';
import { GetSingleCompanyUseCase } from 'domain/usecases';
import { convertCompanyDivisionsToDivisionArray } from '../../main/helpers/convertCompanyDivisionsToDivisionArray';

export class RemoteGetSingleCompanyUseCase implements GetSingleCompanyUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string
  ) {}

  async execute(): Promise<ICompany> {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const company = response.body?.data?.[0] || {};
    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          id: company.id,
          tenant_id: company.attributes.tenant_id,
          name: company.attributes.name,
          fee: parseFloat(company.attributes?.fees?.credit_fee || '0'),
          owner_email: company.attributes.owner_email,
          owner_name: company.attributes.owner_name,
          payment_ach_credentials:
            company.attributes?.payment_credentials?.payment_ach_credentials,
          payment_card_credentials:
            company.attributes?.payment_credentials?.payment_card_credentials,
          ach_fee: company.attributes?.fees?.ach_fee || 0,
          ach_fee_enabled: company.attributes?.fees?.ach_fee_enabled || false,
          ach_fee_percent: company.attributes?.fees?.ach_fee_percent || 0,
          ach_max_fee: company.attributes?.fees?.ach_max_fee || 0,
          credit_fee_enabled:
            company.attributes?.fees?.credit_fee_enabled || false,
          privacy_policy_link: company.attributes?.links?.privacy_policy_link,
          terms_and_conditions_link:
            company.attributes?.links?.terms_and_conditions_link,
          small_logo: company.attributes?.logos?.small_logo,
          large_logo: company.attributes?.logos?.large_logo,
          display_notes: company.attributes?.fees?.display_notes || false,
          divisions: company.attributes?.divisions
            ? convertCompanyDivisionsToDivisionArray(
                company.attributes?.divisions
              )
            : undefined,
        };

      case HttpStatusCode.unauthorized:
        throw new UnauthorizedError('Unauthorized request!');

      default:
        throw new UnexpectedError('Unexpected error!');
    }
  }
}
