import React, { ReactElement } from 'react';
import { ReactSVG } from 'react-svg';
import SearchIcon from '../../../assets/icons/search.svg';
import SearchMobile from '../../../assets/icons/search-mobile.svg';
import HamburgerIcon from '../../../assets/icons/hamburger.svg';
import CollapseLogo from '../../../assets/images/collapsed-include.png';
import * as S from './styles';
import * as T from './types';
import Spacer from 'components/common/Spacer';
import { Button } from 'components';

const TopBar = (props: T.TopBarProps): ReactElement => {
  const { onClickHamburger, links, hiddenSearch, hiddenOnDesktop } = props;

  const styles = {
    hiddenOnDesktop,
  };

  return (
    <S.Container {...styles}>
      <S.TopBar>
        <S.WrapperSearch>
          {!hiddenSearch && (
            <>
              <ReactSVG src={SearchIcon} />
              <S.InputSearch placeholder="Search" />
            </>
          )}
        </S.WrapperSearch>

        <S.MobileWrapper>
          <S.MobileLogoWrapper
            onClick={() => onClickHamburger && onClickHamburger()}
          >
            <img src={CollapseLogo} alt="Include Cloud" />
            <Spacer height="sm" />
            <ReactSVG src={HamburgerIcon} />
          </S.MobileLogoWrapper>
          <ReactSVG src={SearchMobile} />
        </S.MobileWrapper>

        <S.WrapperTopBarLinks>
          {links.map((link) => (
            <S.TopBarLink hiddenMobile={link.hiddenMobile} key={link.name}>
              <Button
                type="button"
                variant="ghost"
                text={link.name}
                onClick={link.linkAction}
              />
            </S.TopBarLink>
          ))}
        </S.WrapperTopBarLinks>
      </S.TopBar>
    </S.Container>
  );
};

export default TopBar;
