import { RadioProps } from './types';
import * as S from './styles';
import { ReactElement } from 'react';

const Radio = (props: RadioProps): ReactElement => {
  const {
    name,
    defaultChecked,
    register,
    testid,
    disabled = false,
    checked,
    onChange,
  } = props;
  return (
    <S.Container>
      <S.RadioItem
        checked={checked}
        type={'radio'}
        name={name}
        defaultChecked={defaultChecked}
        data-testid={testid}
        disabled={disabled}
        onChange={onChange}
        {...(register && register(name))}
      />
    </S.Container>
  );
};

export default Radio;
