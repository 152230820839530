import styled from 'styled-components';

export const ClearButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #0078d4;
  font-size: 0.875rem;
`;

export const Trigger = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: flex-start;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  position: relative;
`;

export const Content = styled.div`
  width: fit-content;
  padding: 0.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background: white;
`;
