import Typography from 'components/common/Typography';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { ReactElement, useRef } from 'react';
import * as S from '../styles';

type ProfileOption = {
  id: string;
  title: string;
  onClick: () => void;
  icon?: ReactElement;
};

interface Props {
  onClose: () => void;
  onSelectOption: (option: ProfileOption) => void;
  onLogout: () => void;
  options: ProfileOption[];
}

const PROFILE_OPTIONS_TITLE = 'Account';

export const ProfileOptions = (props: Props): ReactElement => {
  const { options, onClose } = props;
  const profileOptionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(profileOptionsRef, onClose);

  const onClickOption = (option: ProfileOption) => () => {
    option.onClick();
    onClose();
  };

  return (
    <S.ProfileOptionsWrapper ref={profileOptionsRef}>
      <S.OptionsModalTitleWrapper>
        <Typography variant="caption">{PROFILE_OPTIONS_TITLE}</Typography>
      </S.OptionsModalTitleWrapper>
      {options.map(({ id, title, onClick, icon: Icon }) => (
        <S.OptionsModalRow
          id={`options-modal-row-${id}`}
          data-testid={`options-modal-row-${id}`}
          onClick={() => onClickOption({ id, title, onClick })()}
          key={'OptionsModalRow-' + id}
          current={false}
        >
          <Typography variant="secondaryText">{title}</Typography>
          {!Icon ? null : Icon}
        </S.OptionsModalRow>
      ))}
      <S.LogoutOptionWrapper>
        <S.OptionsModalRow
          id="options-modal-row-logout"
          data-testid="options-modal-row-logout"
          onClick={props.onLogout}
          key="OptionsModalRow-logout"
          current={false}
        >
          <Typography variant="secondaryText">Logout</Typography>
        </S.OptionsModalRow>
      </S.LogoutOptionWrapper>
    </S.ProfileOptionsWrapper>
  );
};
