import styled from 'styled-components';

export const ControlsContaioner = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.black[100]};
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 16px;

  > div {
    border: none;
    background-color: transparent;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.accent.primary[500]};
  }

  & button {
    z-index: 1;
    border: none;
    background: transparent;
    font-family: 'Noto Sans', sans-serif;
    cursor: pointer;
  }

  & button:hover {
    color: ${({ theme }) => theme.colors.accent.primary[500]};
    path {
      fill: ${({ theme }) => theme.colors.accent.primary[500]};
    }
  }
  & button.is-active svg {
    path {
      fill: ${({ theme }) => theme.colors.accent.primary[500]};
    }
  }
`;
