import React, { ReactElement } from 'react';
import * as S from './styles';
import * as T from './types';
import Spinner from './icons/spinner.svg';
import IncludeLoading from './icons/include-loading.svg';
import Icon from '../Icon';

const Loading = (props: T.LoadingProps): ReactElement => {
  const { hiddenLogo, size = 76, bg = 'white' } = props;

  return (
    <S.Container
      bg={bg}
      data-testid={'loading-indicator'}
      id={'loading-indicator'}
    >
      {!hiddenLogo && <Icon width={20} src={IncludeLoading} />}
      <img src={Spinner} alt="Loading" width={size} />
    </S.Container>
  );
};

export default Loading;
