import Typography from 'components/common/Typography';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { ReactElement, useMemo, useRef, useState, useCallback } from 'react';
import * as S from '../styles';
import ArrowDownIcon from '../../../../assets/icons/arrow-down.svg';
import RightIcon from '../../../../assets/icons/right-icon.svg';
import Icon from 'components/common/Icon';

interface Props {
  switchCompanies: { id: string; title: string; current?: boolean }[];
  closeSwitch: () => void;
  onSelectCompany: (company: {
    id: string;
    title: string;
    current?: boolean;
  }) => void;
}

const COMPANIES_SELECTION_TITLE = 'Change Company';

export const CompaniesSelectionLinks = (props: Props): ReactElement => {
  const { switchCompanies = [], closeSwitch, onSelectCompany } = props;
  const companySelectorRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState(true);
  useOnClickOutside(companySelectorRef, closeSwitch);

  const companies = useMemo(() => {
    const currentOnTop = switchCompanies.sort((a) => {
      if (a.current) {
        return -1;
      }
      return 0;
    });
    if (collapsed) {
      return currentOnTop.slice(0, 6);
    }
    return currentOnTop;
  }, [switchCompanies, collapsed]);

  const onClickCompany = useCallback(
    (company: { id: string; title: string; current?: boolean }) => () => {
      onSelectCompany(company);
      closeSwitch();
    },
    [onSelectCompany, closeSwitch]
  );
  return (
    <S.CompanySelectionWrapper
      id={'quick-company-selector'}
      data-testid={'quick-company-selector'}
      ref={companySelectorRef}
    >
      <S.CompanySelectorTitleWrapper>
        <Typography variant="caption">{COMPANIES_SELECTION_TITLE}</Typography>
      </S.CompanySelectorTitleWrapper>
      {companies.map(({ id, title, current = false }) => (
        <S.CompanySelectionCompanyRow
          id={`company-selection-row-${id}`}
          data-testid={`company-selection-row-${id}`}
          onClick={onClickCompany({ id, title, current })}
          key={id}
          current={current}
        >
          <div
            style={{
              minWidth: '24px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {current && <Icon src={RightIcon} />}
          </div>

          <Typography variant="secondaryText">{title}</Typography>
        </S.CompanySelectionCompanyRow>
      ))}
      {switchCompanies.length > 6 && collapsed && (
        <S.CompanySelectionExpandWrapper
          onClick={() => setCollapsed((c) => !c)}
        >
          <Icon src={ArrowDownIcon} />
        </S.CompanySelectionExpandWrapper>
      )}
    </S.CompanySelectionWrapper>
  );
};
