import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ViewContainer = styled.div`
  min-height: 28px;
  border-radius: 0.5rem;
  padding: 4px 8px;
  width: 100%;
  cursor: text;

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  }

  color: ${({ theme }) => theme.colors.neutral.black[600]};
`;

export const EditContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const ActionButtons = styled.div<{
  containerHeight: number;
}>`
  position: absolute;
  right: 0;
  top: ${({ containerHeight }) => containerHeight - 18}px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ActionButton = styled.button`
  background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  border: none;
  padding: 0.2rem;
  border-radius: 0.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[300]};
  }
`;
