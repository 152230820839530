import styled from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

export const Container = styled(TooltipPrimitive.Root)``;

export const Trigger = styled(TooltipPrimitive.Trigger)`
  background-color: transparent;
`;

export const Portal = styled(TooltipPrimitive.Portal)``;

export const Content = styled(TooltipPrimitive.Content)`
  z-index: 11;
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacings.sm}px;
  max-width: 25rem;
  font-size: 0.875rem;
  font-weight: 400;
  background: ${({ theme }) => theme.colors.neutral.black[600]};
  color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Arrow = styled(TooltipPrimitive.Arrow)`
  fill: ${({ theme }) => theme.colors.neutral.black[600]};
`;
