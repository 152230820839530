import styled, { css } from 'styled-components';

export const Container = styled.div<{
  bg: 'transparent' | 'white';
}>`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${({ bg }) =>
    bg === 'transparent' &&
    css`
      background-color: transparent;
    `}
`;
