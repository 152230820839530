import styled from 'styled-components';
import * as SwitchPrimitive from '@radix-ui/react-switch';

export const SwitchRoot = styled(SwitchPrimitive.Root)`
  all: unset;
  width: 42px;
  height: 25px;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.accent.primary[200] : theme.colors.neutral.gray};
  border-radius: 9999px;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  :focus {
    box-shadow: 0 0 0 2px black;
  }
`;

export const SwitchThumb = styled(SwitchPrimitive.Thumb)({
  display: 'block',
  width: 21,
  height: 21,
  backgroundColor: 'white',
  borderRadius: '9999px',
  boxShadow: `0 2px 2px #ccc`,
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&[data-state="checked"]': { transform: 'translateX(19px)' },
});

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 700px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
`;
export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`;
