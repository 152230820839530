import { SideBar, TopBar } from 'include-ui';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { light as theme } from 'presentation/themes/light';
import * as S from './styles';
import { sideBarLinks } from './sideBarLinks';
import { useNavigate } from 'react-router-dom';
import HomeIcon from './icons/home.svg';
import ChatIcon from './icons/chat.svg';
import ContactsIcon from './icons/contacts.svg';
import { useLocation } from 'react-router-dom';
import { SelfContext } from 'presentation/contexts/selfContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useHasPermission } from 'presentation/hooks';

const LayoutTemplate: React.FC = ({ children }) => {
  const [collapse, setCollapse] = useState(false);
  useContext(SelfContext);
  const { logout, user } = useAuth0();

  const {
    onSelectCompany,
    companies,
    companyLargeLogo,
    companySmalllogo,
    companyName,
    profilePic,
    companyId = '',
    tenantId = '',
    permissions: initialPermissions,
  } = useContext(SelfContext);
  const navigate = useNavigate();

  const permissions: string[] = [];

  initialPermissions.forEach((permission) => {
    permissions.push(permission);
  });

  useEffect(() => {
    const handleResize = () => {
      const breakpoint = Number(theme.breakpoints.md.replace('px', ''));

      setCollapse(window.innerWidth <= breakpoint);
    };
    handleResize();
  }, []);

  const switchCompanies = useMemo(() => {
    return companies.map(({ id, name }) => ({
      id,
      title: name,
      current: companyId === id,
    }));
  }, [companies, companyId]);

  const { pathname } = useLocation();

  const hasPermissions = useHasPermission();

  const isPageActive = (path: string) => {
    return pathname.includes(path);
  };

  const currentRoute = useLocation().pathname;

  return (
    <S.Container>
      <S.Overlay overlay={!collapse} />
      <SideBar
        profileName={user?.name}
        profileImage={profilePic}
        hasProfileLink
        profileAction={() => navigate('/admin/profile')}
        headerLinks={[
          {
            name: 'Dashboard',
            action: () => navigate('/'),
            active: pathname === '/' || pathname.includes('/home'),
            icon: HomeIcon,
          },
          {
            name: 'Messages',
            action: () => navigate('/go/messages'),
            active:
              pathname === '/go/messages' || pathname.includes('/go/messages'),
            icon: ChatIcon,
            hide: true,
          },
          {
            name: 'Contacts',
            action: () => navigate('/go/contacts'),
            active:
              pathname === '/go/contacts' || pathname.includes('/go/contacts'),
            icon: ContactsIcon,
            hide:
              !hasPermissions(
                `when:companies:${companyId}:grant:clients:all:`
              ) &&
              !hasPermissions(
                `when:companies:${companyId}:grant:clients:read:`
              ),
          },
        ]}
        onCollapseChange={setCollapse}
        collapsed={collapse}
        clientCollapsedLogo={companySmalllogo}
        clientLogo={companyLargeLogo}
        linkGroup={sideBarLinks(
          navigate,
          isPageActive,
          hasPermissions,
          tenantId,
          companyId
        )}
        displaySwitchButton
        switchCompanies={switchCompanies}
        onSelectCompany={onSelectCompany}
        selectButtonLabel={companyName}
        onLogout={() => logout({ returnTo: window.location.origin })}
      />

      <S.TopBarWrapper id="main-scroll-wrapper">
        <TopBar
          hiddenOnDesktop
          links={[]}
          onClickHamburger={() => setCollapse(false)}
        />

        <S.Content bg={currentRoute.includes('/go') ? 'gray' : 'white'}>
          {children}
        </S.Content>
      </S.TopBarWrapper>
    </S.Container>
  );
};

export default LayoutTemplate;
