import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const DraggableItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  margin: 4px 0;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.black[200]};
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  }

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
  }
`;
