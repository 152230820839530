import styled, { css, keyframes } from 'styled-components';
import * as Dialog from '@radix-ui/react-dialog';

type ContentProps = {
  width?: number;
  minHeight?: number;
};

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const Container = styled(Dialog.Root)`
  z-index: ${({ theme }) => theme.layerindex.dialog};
`;

export const Title = styled(Dialog.Title)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 130%;
  height: 40px;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.title};
`;

export const Trigger = styled(Dialog.Trigger)``;

export const Portal = styled(Dialog.Portal)``;

export const Content = styled(Dialog.Content)<ContentProps>`
  z-index: ${({ theme }) => theme.layerindex.dialog};
  position: fixed;
  max-width: 526px;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 20px 24px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  min-height: 304px;

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight}px;
    `}

  @media (prefers-reduced-motion: no-preference) {
    animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &:focus {
    outline: 'none';
  }

  ${({ width }) =>
    width &&
    css`
      max-width: ${width}px;
      width: 100%;
    `}
`;

export const Overlay = styled.div`
  z-index: ${({ theme }) => theme.layerindex.overlay};
  background: rgba(0 0 0 / 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  inset: 0;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
`;

export const Close = styled(Dialog.Close)`
  position: absolute;
  background-color: transparent;
  border-radius: 6px;
  bottom: 20px;
  right: 24px;
  display: flex;
  gap: ${({ theme }) => theme.spacings.sm}px;
`;
