import { RemoteGetProfileUseCase } from 'data/usecases';
import { GetProfileUseCase } from 'domain/usecases';
import { makeAxiosHttpClient, makeAdminApiUrl } from 'main/factories/http';
import { getAuthToken } from 'main/helpers/get-auth-token';

export const makeRemoteGetProfileUseCase = (): GetProfileUseCase => {
  const accessToken = getAuthToken();
  const url = makeAdminApiUrl(`/user/profile`);

  return new RemoteGetProfileUseCase(makeAxiosHttpClient(accessToken), url);
};
