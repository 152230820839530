import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ReactSVG } from 'react-svg';
import * as S from './styles';
import * as T from './types';
import ArrowDownIcon from '../../../assets/icons/arrow-down.svg';
import HelpIcon from '../../../assets/icons/help-footer.svg';
import CollapseIcon from './icons/collapse.svg';
import CollapsedIcon from './icons/collapsed.svg';
import MailToIcon from './icons/mail-to-icon.svg';
import FallbackAvatar from './icons/fallback-avatar.svg';
import HamburgerIcon from '../../../assets/icons/hamburger.svg';
import SidebarCog from '../../../assets/icons/cog-sidebar.svg';
import { light as theme } from 'themes/light';
import { Spacer, Button, Typography } from 'components';
import { helpOptions } from './helpOptions';
import { findLogoUrlByCollapse } from './actions';
import useOnClickOutside from 'hooks/useOnClickOutside';
import MobileMenuLinks from './atoms/MobileMenu';
import GroupLink from './atoms/GroupLink';
import { CompaniesSelectionLinks } from './molecules/CompaniesSelectionLinks';
import { ReactComponent as DropIcon } from '../../../assets/icons/dropdown.svg';
import { ProfileOptions } from './molecules/ProfileSelectionLinks';

const truncateWord = (word: string, limit: number): string => {
  if (word.length > limit) {
    return word.slice(0, limit) + '...';
  }
  return word;
};

const SideBar = (props: T.SideBarProps): ReactElement => {
  const {
    collapsed,
    onCollapseChange,
    linkGroup,
    clientCollapsedLogo,
    clientLogo,
    hasProfileLink,
    profileName = 'Tiago Dias',
    profileImage,
    headerLinks,
    displaySwitchButton,
    switchCompanies = [],
    profileAction,
    onSelectCompany,
    selectButtonLabel,
    onLogout,
  } = props;

  const [expandHelp, setExpandHelp] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(collapsed);
  const [switchCompanyOpen, setSwitchCompanyOpen] = useState(false);
  const [profileOptionsOpen, setProfileOptionsOpen] = useState(false);

  const pathname = window.location.pathname;

  const styles = {
    collapse,
  };

  const toggleSwitchCompanyOpen = useCallback(() => {
    setSwitchCompanyOpen((isOpen) => !isOpen);
  }, [setSwitchCompanyOpen]);

  const toggleProfileOptionsOpen = useCallback(() => {
    setProfileOptionsOpen((isOpen) => !isOpen);
  }, [setProfileOptionsOpen]);

  const onClickMailTo = useCallback(() => {
    window.open('mailto:team@include.com');
  }, []);

  useEffect(() => {
    setCollapse(collapsed);
  }, [collapsed]);

  const triggerOnCollapseChange = useCallback(() => {
    onCollapseChange(collapse);
  }, [collapse]);

  useEffect(() => {
    triggerOnCollapseChange();
  }, [collapse, triggerOnCollapseChange]);

  const helpLinksRef = useRef<HTMLDivElement>(null);
  const sideBarRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(helpLinksRef, () => collapse && setExpandHelp(false));

  useOnClickOutside(sideBarRef, () => {
    const breakpoint = Number(theme.breakpoints.md.replace('px', ''));
    const isMobileSize = window.innerWidth < breakpoint;
    if (isMobileSize) {
      setCollapse(true);
    }
  });

  return (
    <S.Container data-testid="side-bar" ref={sideBarRef} {...styles}>
      <S.Header {...styles}>
        <S.WrapperLogo className={collapse ? 'mobile' : ''}>
          <img
            src={findLogoUrlByCollapse(
              collapse,
              clientLogo,
              clientCollapsedLogo
            )}
            alt={collapse ? 'Include Collapsed' : 'Include'}
          />

          <S.WrapperMenuMobile onClick={() => setCollapse(true)}>
            <ReactSVG src={HamburgerIcon} />
          </S.WrapperMenuMobile>
          {hasProfileLink && profileName && !collapsed && (
            <S.ProfileMenuLink
              style={{ cursor: 'pointer' }}
              onClick={toggleProfileOptionsOpen}
            >
              {profileImage ? (
                <img src={profileImage} />
              ) : (
                <ReactSVG wrapper="svg" src={FallbackAvatar} />
              )}
              <S.ProfileCogWrapper>
                <ReactSVG
                  wrapper="svg"
                  src={SidebarCog}
                  preserveAspectRatio="xMidYMid meet"
                />
              </S.ProfileCogWrapper>
            </S.ProfileMenuLink>
          )}
        </S.WrapperLogo>
        <Spacer height="sm" />
        {hasProfileLink && profileName && collapsed && (
          <div>
            <S.ProfileMenuLink
              style={{ cursor: 'pointer' }}
              onClick={toggleProfileOptionsOpen}
            >
              {profileImage ? (
                <img src={profileImage} />
              ) : (
                <ReactSVG wrapper="svg" src={FallbackAvatar} />
              )}
            </S.ProfileMenuLink>
          </div>
        )}
        <S.MobileRow>
          {displaySwitchButton && onSelectCompany && (
            <S.MobileSwitchButtonWrapper>
              <Button
                onClick={toggleSwitchCompanyOpen}
                size="small"
                text={selectButtonLabel || 'Select a company'}
                variant="outline"
              />
            </S.MobileSwitchButtonWrapper>
          )}
          {hasProfileLink && profileName && (
            <S.ProfileMenuMobileLink
              style={{ cursor: 'pointer' }}
              onClick={toggleProfileOptionsOpen}
            >
              {profileImage ? (
                <img src={profileImage} />
              ) : (
                <ReactSVG wrapper="svg" src={FallbackAvatar} />
              )}
              <S.ProfileCogWrapper>
                <ReactSVG
                  wrapper="svg"
                  src={SidebarCog}
                  preserveAspectRatio="xMidYMid meet"
                />
              </S.ProfileCogWrapper>
            </S.ProfileMenuMobileLink>
          )}
        </S.MobileRow>
        {displaySwitchButton && onSelectCompany && !collapse && (
          <S.SelectCompanyButton
            id={'company-switch-button'}
            onClick={toggleSwitchCompanyOpen}
          >
            <S.SelectCompanyButtonTitleWrapper>
              <Typography variant="secondaryText">
                {truncateWord(
                  selectButtonLabel ||
                    'Select a company with a super long name',
                  20
                )}
              </Typography>
            </S.SelectCompanyButtonTitleWrapper>
            <DropIcon />
          </S.SelectCompanyButton>
        )}
        <Spacer height="md" />

        {switchCompanyOpen && onSelectCompany && (
          <CompaniesSelectionLinks
            switchCompanies={switchCompanies}
            closeSwitch={() => setSwitchCompanyOpen(false)}
            onSelectCompany={onSelectCompany}
          />
        )}
        {profileOptionsOpen && (
          <ProfileOptions
            onClose={toggleProfileOptionsOpen}
            onSelectOption={toggleProfileOptionsOpen}
            onLogout={onLogout}
            options={[
              {
                id: 'profile',
                title: 'Manage Profile',
                onClick: profileAction ? profileAction : () => null,
              },
              {
                id: 'report',
                title: 'Report an Issue',
                onClick: onClickMailTo,
                icon: <ReactSVG src={MailToIcon} />,
              },
            ]}
          />
        )}
      </S.Header>

      <S.Content {...styles}>
        <div>
          <S.MainLinkWrapper>
            {headerLinks &&
              headerLinks.map(
                (link, index) =>
                  !link.hide && (
                    <a href={link.linkTo} key={index}>
                      <S.MenuLink
                        onClick={() => {
                          const breakpoint = Number(
                            theme.breakpoints.md.replace('px', '')
                          );
                          const isMobileSize = window.innerWidth < breakpoint;
                          if (isMobileSize) {
                            setCollapse(true);
                          }
                          link.action?.();
                        }}
                        active={link.active}
                      >
                        {link.icon && <ReactSVG src={link.icon} />}
                        {!collapse && link.name}
                      </S.MenuLink>
                    </a>
                  )
              )}

            <MobileMenuLinks collapse={collapse} links={[]} />
          </S.MainLinkWrapper>

          <GroupLink
            collapse={collapse}
            setCollapse={setCollapse}
            linkGroup={linkGroup.filter((g) => g.links.some((l) => !l.hide))}
          />
        </div>
      </S.Content>
      <div>
        <S.Section collapsed={collapse}>
          <S.SectionHeader onClick={() => setExpandHelp(!expandHelp)}>
            <span>
              <S.HelpIconWrapper>
                <ReactSVG color="red" src={HelpIcon} height={24} width={24} />
              </S.HelpIconWrapper>
              {!collapse && 'Help'}
            </span>

            <ReactSVG src={ArrowDownIcon} />
          </S.SectionHeader>

          {expandHelp && (
            <S.WrapperLinks ref={helpLinksRef}>
              {!collapse && <Spacer height="sm" />}
              <S.SectionLinks
                offsetAlign="bottom"
                offsetTab={collapse && expandHelp}
              >
                {helpOptions.map((link, index) => (
                  <S.SectionLink key={link.name + `${index}`}>
                    <a href={link.linkTo}>{link.name}</a>
                  </S.SectionLink>
                ))}
              </S.SectionLinks>
            </S.WrapperLinks>
          )}
        </S.Section>

        <S.WrapperCollapse onClick={() => setCollapse(!collapse)}>
          <S.CollapseText collapsed={collapse}>Collapse menu</S.CollapseText>

          <span data-testid="collapse-trigger">
            <ReactSVG src={collapse ? CollapsedIcon : CollapseIcon} />
          </span>
        </S.WrapperCollapse>
      </div>
    </S.Container>
  );
};

export default SideBar;
