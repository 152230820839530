import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import GlobalStyles from '../themes';
import { ThemeProvider } from 'styled-components';
import { Loading, light } from 'include-ui';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LayoutTemplate from './template';
import { SelfContextProvider } from 'presentation/contexts/selfContext';
import { useAuth0 } from '@auth0/auth0-react';
import runOneSignal from './onesignal';
import { useAccountId } from 'presentation/hooks/useAccountId';

const AdminApp = lazy(() => import('cloudadminspa/'));
const PaymentsApp = lazy(() => import('portalspa'));
const IncludeGo = lazy(() => import('includego'));

type Apps = 'admin' | 'payments' | 'go';

const Layout = (): JSX.Element => {
  const { user, isLoading } = useAuth0();

  const MemoizedAdminApp = React.memo(AdminApp);
  const MemoizedPaymentsApp = React.memo(PaymentsApp);
  const MemoizedIncludeGo = React.memo(IncludeGo);

  const currentRoute = useLocation().pathname;

  const unauthenticatedRoutes = ['/invite', '/signup', '/public'];

  function isUnauthenticatedRoute(): boolean {
    return unauthenticatedRoutes.some((route) =>
      currentRoute.startsWith(route)
    );
  }

  const [app, setApp] = useState<Apps>('payments');

  function switchApp(currentRoute: any): void {
    const route = currentRoute.split('/')[1] || 'home';

    switch (route) {
      case 'admin':
      case 'invite':
        setApp('admin');
        break;
      case 'payments':
      case 'home':
      case 'public':
        setApp('payments');
        break;
      case 'go':
        setApp('go');
        break;
      default:
        setApp('payments');
        break;
    }
  }

  const renderApp = useCallback(() => {
    switch (app) {
      case 'admin':
        return <MemoizedAdminApp />;
      case 'payments':
        return <MemoizedPaymentsApp />;
      case 'go':
        return <MemoizedIncludeGo />;
      default:
        return <MemoizedPaymentsApp />;
    }
  }, [app]);

  useEffect(() => {
    switchApp(currentRoute);
  }, [currentRoute]);

  const accountId = useAccountId();

  useEffect(() => {
    if (accountId) {
      runOneSignal(accountId);
    }
  }, [user, accountId]);

  return (
    <>
      <ThemeProvider theme={light}>
        {isLoading ? (
          <Loading />
        ) : (
          <Suspense fallback={<Loading />}>
            {isUnauthenticatedRoute() ? (
              <div key={app}>{renderApp()}</div>
            ) : (
              <SelfContextProvider>
                <LayoutTemplate key={app}>{renderApp()}</LayoutTemplate>
              </SelfContextProvider>
            )}
          </Suspense>
        )}
      </ThemeProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GlobalStyles />
    </>
  );
};

export default Layout;
