/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { defaultInputValidationMessages } from 'config/defaultInputValidationMessages';
import { ReactHookFormValidations } from './types';

export function formatValidationMessages(
  message: string,
  value: string | number
): string {
  return message.replace('{0}', value.toString());
}

export const generateValidations = (validations: ReactHookFormValidations) => {
  const { required, minLength, maxLength, min, max, pattern } = validations;

  return {
    required: required && {
      value: true,
      message: defaultInputValidationMessages.required,
    },
    minLength: minLength && {
      value: minLength,
      message: formatValidationMessages(
        defaultInputValidationMessages.minLength,
        minLength
      ),
    },
    maxLength: maxLength && {
      value: maxLength,
      message: formatValidationMessages(
        defaultInputValidationMessages.maxLength,
        maxLength
      ),
    },
    min: min && {
      value: min,
      message: formatValidationMessages(
        defaultInputValidationMessages.min,
        min
      ),
    },

    max: max && {
      value: max,
      message: formatValidationMessages(
        defaultInputValidationMessages.max,
        max
      ),
    },
    pattern: pattern && {
      value: pattern,
      message: defaultInputValidationMessages.pattern,
    },
  };
};
