import { HttpClient, HttpStatusCode } from 'data/protocols/http/HttpClient';
import { UnauthorizedError } from 'domain/errors';
import { GetProfileUseCase, ProfileResponseDTO } from 'domain/usecases';

export class RemoteGetProfileUseCase implements GetProfileUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string
  ) {}

  async execute(): Promise<ProfileResponseDTO> {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });

    const profileData = response.body.data[0];

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          name: profileData.attributes.profile.name,
          email: profileData.attributes.profile.email,
          nickname: profileData.attributes.profile.nickname,
          last_name: profileData.attributes.profile.family_name,
          middle_name: profileData.attributes.profile.given_name,
          phone_number: profileData.attributes.extra.phone_number,
          country: profileData.attributes.extra.country,
          address: profileData.attributes.extra.address,
          birthdate: profileData.attributes.extra.birthdate,
          city: profileData.attributes.extra.city,
          postal: profileData.attributes.extra.postal,
          salutation: profileData.attributes.extra.salutation,
          state: profileData.attributes.extra.state,
          suffix: profileData.attributes.extra.suffix,
          website: profileData.attributes.extra.website,
          picture: profileData.attributes.profile.picture,
          default_picture: profileData.attributes.profile.default_picture,
        };

      case HttpStatusCode.unauthorized:
        throw new UnauthorizedError('Unauthorized request');

      default:
        throw new Error('Unexpected error');
    }
  }
}
