import { SelfContext } from 'presentation/contexts/selfContext';
import { useCallback, useContext } from 'react';

export const useHasPermission = (): ((permission: string) => boolean) => {
  const { permissions = new Set() } = useContext(SelfContext);
  const checkIfHasPermission = useCallback(
    (requestedPermission: string) => {
      let found = false;
      permissions.forEach(function(foundPermission) {
        if (String(foundPermission).includes(requestedPermission)) {
          found = true;
        }
      });
      return found;
    },
    [permissions]
  );

  return checkIfHasPermission;
};
