import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Overlay = styled.div<{ overlay?: boolean }>`
  z-index: ${({ theme }) => theme.layerindex.overlay};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    ${({ overlay }) =>
      overlay &&
      css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
      `}
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-basis: content;
  height: fit-content;
  min-height: 100vh;
  height: 100%;
  background-color: #ededed;
`;

export const SwitchCompanyLayout = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TopBarWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Content = styled.div<{ bg?: 'white' | 'gray' }>`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xl}px;
  background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  min-height: 100vh;
  ${({ bg }) =>
    bg === 'white' &&
    css`
      background-color: ${({ theme }) => theme.colors.neutral.white};
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 ${({ theme }) => theme.spacings.lg}px;
  }
`;
