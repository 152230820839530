import styled, { css } from 'styled-components';
import { AlertType } from './types';

interface AlertStyledProps {
  type: AlertType;
  fullWidth?: boolean;
  padding?: string;
  fontsize?: number;
  hideborder?: boolean;
}

interface TextProps {
  textAlign?: 'left' | 'center' | 'right';
  descriptionLineHeight?: number;
  fontsize?: number;
}

function pixelToRem(pixel: number) {
  return `${pixel / 16}rem`;
}

export const Container = styled.div<AlertStyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacings.sm}px;
  line-height: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({ type }) =>
    type === 'success' &&
    css`
      background-color: ${({ theme }) => theme.colors.accent.secondary[200]};
      border-color: ${({ theme }) => theme.colors.accent.secondary[700]};
      color: ${({ theme }) => theme.colors.accent.secondary[700]};
    `}

  ${({ type }) =>
    type === 'warning' &&
    css`
      background-color: ${({ theme }) => theme.colors.alert.warning};
      border-color: ${({ theme }) => theme.colors.alert.warningBorder};
      color: ${({ theme }) => theme.colors.alert.warningText};
    `}

  ${({ type }) =>
    type === 'info' &&
    css`
      background-color: ${({ theme }) => theme.colors.alert.info};
      border-color: ${({ theme }) => theme.colors.alert.infoBorder};
      color: ${({ theme }) => theme.colors.alert.infoText};
    `}

  ${({ type }) =>
    type === 'error' &&
    css`
      background-color: ${({ theme }) => theme.colors.alert.error};
      border-color: ${({ theme }) => theme.colors.alert.errorBorder};
      color: ${({ theme }) => theme.colors.alert.errorText};
    `}

    & svg {
    margin-right: ${({ theme }) => theme.spacings.md}px;
    cursor: pointer;

    ${({ type }) =>
      type === 'success' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.accent.secondary[700]};
        }
      `}

    ${({ type }) =>
      type === 'info' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.alert.infoText};
        }
      `}

    ${({ type }) =>
      type === 'error' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.alert.errorText};
        }
      `}

    ${({ type }) =>
      type === 'warning' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.alert.warningText};
        }
      `}
  }

  ${({ hideborder }) =>
    hideborder &&
    css`
      border: 1px solid transparent;
    `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacings.sm}px;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.span<TextProps>`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`}
`;

export const Description = styled.span<TextProps>`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.063rem;

  ${({ fontsize }) =>
    fontsize &&
    css`
      font-size: ${pixelToRem(fontsize)};
    `}

  ${({ descriptionLineHeight }) =>
    descriptionLineHeight &&
    css`
      line-height: ${descriptionLineHeight}rem;
    `}

    ${({ textAlign }) => textAlign && `text-align: ${textAlign}`}
`;
