import styled from 'styled-components';

export const HeaderWrapper = styled.div<{
  align: 'left' | 'center' | 'right';
}>`
  display: flex;
  justify-content: ${({ align }) =>
    align === 'center'
      ? 'center'
      : align === 'right'
      ? 'flex-end'
      : 'flex-start'};
  width: 100%;
`;

export const DetailTemplateWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 1rem;
`;

export const RowContainer = styled.div`
  position: relative;
`;

export const ActionsContainer = styled.div`
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
  z-index: 1;
  background-color: white;
`;

export const CellContainer = styled.div`
  display: inline-block;
  text-overflow: clip;
  /* overflow-x: hidden; */
`;
