import { HttpClient } from 'data/protocols/http/HttpClient';
import { ICompany } from 'domain/entities/company';
import { ListCompanyUseCase } from 'domain/usecases';

export class RemoteListCompanies implements ListCompanyUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly urlAdminCompanies: string,
    private readonly urlPaymentsCompanies: string
  ) {}

  async execute(): Promise<ICompany[]> {
    const [responseAdminCompanies, responsePaymentsCompanies] =
      await Promise.allSettled([
        this.httpClient.request({
          url: this.urlAdminCompanies + '&app=core',
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        }),
        this.httpClient.request({
          url: this.urlPaymentsCompanies,
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        }),
      ]);
    const adminCompanyData =
      responseAdminCompanies.status !== 'fulfilled'
        ? []
        : responseAdminCompanies.value.body['data'] || [];
    const paymentsCompanyData =
      responsePaymentsCompanies.status !== 'fulfilled'
        ? []
        : responsePaymentsCompanies.value.body['data'] || [];

    const output = adminCompanyData
      .concat(
        paymentsCompanyData.filter(
          (c) => !adminCompanyData.find((a) => a.id === c.id)
        )
      )
      .map((company) => ({
        id: company.id,
        name: company.attributes.name,
        owner_email: company.attributes.owner_email,
        owner_name: company.attributes.owner_name,
        tenant_id: company.attributes.tenants_id,
        small_logo: company.attributes?.logos?.small_logo,
        large_logo: company.attributes?.logos?.large_logo,
        agent_id: company.attributes?.links?.agent_id || '',
      }));

    return output;
  }
}
