import styled, { css } from 'styled-components';

export const Container = styled.div<{ active: boolean }>`
  ${({ active }) =>
    active &&
    css`
      z-index: ${({ theme }) => theme.layerindex.overlay};
      pointer-events: none;
      opacity: 0.3;
    `}
`;

export const Content = styled.div`
  position: relative;
`;
