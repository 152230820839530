import styled, { css } from 'styled-components';

interface LinkProps {
  height?: number;
  active?: boolean;
}

export const Container = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  align-items: center;
  max-width: 197px;
  cursor: pointer;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      max-width: 100%;
    `}
`;

export const Link = styled.div<LinkProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid transparent;
  position: relative;
  border-radius: 4px;
  width: 100%;

  ${({ height = 32 }) => css`
    height: ${height}px;
  `}

  > svg {
    display: none;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};

    > svg {
      display: block;
    }
  }

  ${({ active }) =>
    active &&
    css`
      ::after {
        content: '';
        position: absolute;
        left: 0;
        background-color: ${({ theme }) => theme.colors.accent.secondary[800]};
        height: 80%;
        border-radius: 12px;
        width: 4px;
      }
      background-color: ${({ theme }) => theme.colors.neutral.black[200]};
    `}
`;

export const WrapText = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.button`
  outline: none;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: ${({ theme }) => theme.colors.text.secondary};

  :hover {
    color: ${({ theme }) => theme.colors.text.primary};
    text-decoration-line: underline;
  }

  :focus {
    color: ${({ theme }) => theme.colors.text.primary};
    text-decoration-line: underline;
  }
`;
