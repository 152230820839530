import styled from 'styled-components';
import { StatcardProps } from './types';

export const Container = styled.div<Pick<StatcardProps, 'variant' | 'status'>>`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: ${({ variant }) => (variant === 'dashboard' ? 16 : 4)}px;

  /* White */

  background: #ffffff;
  /* Borders/Default */

  border: 1px solid
    ${({ status, theme }) =>
      status === 'error'
        ? theme.colors.status.danger
        : status === 'warning'
        ? theme.colors.status.warning
        : theme.colors.borders.default};
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
