import { MenuLink } from './types';

export const helpOptions: MenuLink[] = [
  {
    name: 'Videos',
    linkTo: '/help/videos',
  },
  {
    name: 'Documentation',
    linkTo: '/help/documentation',
  },
  {
    name: 'Message team@include',
    linkTo: '/help/message-team',
  },
];
