import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: auto;
`;

export const RadioItem = styled.input`
  display: grid;
  place-content: center;
  appearance: none;
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.colors.accent.secondary[800]};
  box-shadow: none;
  :hover {
    border: 3px solid ${({ theme }) => theme.colors.accent.secondary[800]};
    background-color: ${({ theme }) => theme.colors.neutral.gray};
  }
  :focus {
    border: 3px solid ${({ theme }) => theme.colors.accent.active};
  }
  &:checked {
    &::after {
      content: '';
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.accent.secondary[800]};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      border-color: gray;
      cursor: no-drop;
      :hover {
        border-color: gray;
      }
    `}
`;

export const RadioChecked = styled.div``;
