import styled, { css } from 'styled-components';

interface StyledLinkProps {
  external?: boolean;
  hoverCard?: boolean;
  as?: 'a' | 'button';
  size?: 'small' | 'medium';
}

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLink = styled.a.attrs<StyledLinkProps>(({ as = 'a' }) => ({
  as,
}))<StyledLinkProps>`
  font-family: 'Inter';
  font-style: normal;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.accent.primary[500]};
  gap: ${({ theme }) => theme.spacings.sm}px;

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 0.875rem;
      font-weight: 400;
    `}

  :hover {
    color: ${({ theme }) => theme.colors.accent.primary[600]};

    & svg {
      path {
        fill: ${({ theme }) => theme.colors.neutral.black[500]};
      }
    }
  }

  ${({ hoverCard }) =>
    hoverCard &&
    css`
      color: ${({ theme }) => theme.colors.text.primary};

      ::before {
        transition: height 0.2s ease-in-out;
        content: '';
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: ${({ theme }) => theme.colors.accent.secondary[100]};
        border-radius: 4px;
      }
      :hover::before {
        height: 100%;
      }

      :hover {
        color: ${({ theme }) => theme.colors.text.primary};
      }
    `}
`;
