export function getAuthToken(): string {
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const API = process.env.REACT_APP_AUDIENCE;

  type TokenProps = {
    body: {
      access_token: string;
    };
  };

  const token = localStorage.getItem(
    `@@auth0spajs@@::${CLIENT_ID}::${API}::openid profile email`
  );

  if (token) {
    const tokenJSON = JSON.parse(token) as TokenProps;
    return tokenJSON.body.access_token;
  }

  return '';
}
