import React, { useEffect, useState } from 'react';
import * as T from './types';
import * as S from './styles';
import { ReactSVG } from 'react-svg';
import { ErrorMessage } from '@hookform/error-message';
import { generateValidations } from './actions';
import Spinner from './icons/spinner.svg';
import FluentIcon from '../FluentIcon/component';

const Input: React.ForwardRefRenderFunction<HTMLInputElement, T.InputProps> = (
  props,
  ref
) => {
  const {
    name,
    value,
    placeholder,
    label,
    type,
    autoComplete,
    errors,
    fullWidth,
    disabled,
    height,
    icon,
    defaultValue,
    validations,
    register,
    onChange,
    clearButton,
    onClear,
    focusColor,
    autoFocus,
    description,
    id,
    mask,
    loading,
    iconElement,
    min,
    max,
    onFocus,
    onKeyPress,
  } = props;

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    if (mask) {
      const maskedValue = mask(value);

      if (maskedValue) {
        e.target.value = maskedValue;
      }
    }

    onChange && onChange(e);
  }

  const [active, setActive] = useState(false);

  //if value is not empty, set active to true
  useEffect(() => {
    if (value) {
      setActive(true);
    }
  }, [value]);

  return (
    <S.Container fullWidth={fullWidth} data-testid={`${name}-wrap`}>
      <S.InputWrapper>
        {icon && !iconElement ? (
          <S.IconWrapper>
            <FluentIcon iconName={icon as any} />
          </S.IconWrapper>
        ) : null}

        {iconElement ? <S.IconWrapper>{iconElement}</S.IconWrapper> : null}

        {clearButton ? (
          <S.WrappedIcon onClick={onClear} isFullMode={fullWidth}>
            <FluentIcon iconName="Close24Regular" />
          </S.WrappedIcon>
        ) : null}

        {label && active ? <S.Label active={true}>{label}</S.Label> : null}

        <S.Input
          onFocus={onFocus}
          onFocusCapture={() => setActive(true)}
          onBlur={(e) => {
            if (!e.target.value) {
              setActive(false);
            }
          }}
          id={id}
          ref={ref}
          hasIcon={!!icon || !!iconElement}
          hasClearButton={clearButton}
          height={height}
          focusColor={focusColor}
          data-testid={name}
          fullWidth={fullWidth}
          error={errors && errors[name]}
          autoComplete={autoComplete}
          type={type}
          value={value}
          disabled={disabled}
          min={min}
          max={max}
          defaultValue={defaultValue}
          placeholder={!active ? label || placeholder : placeholder}
          onChange={handleOnChange}
          autoFocus={autoFocus}
          onKeyPress={onKeyPress}
          {...(register &&
            register(name, validations && generateValidations(validations)))}
        />

        {loading && (
          <S.WrappedIcon isFullMode={fullWidth}>
            <ReactSVG src={Spinner} width={24} height={24} />
          </S.WrappedIcon>
        )}
      </S.InputWrapper>

      {description && (errors && errors[name]) === undefined && (
        <S.DescriptionText>{description}</S.DescriptionText>
      )}
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <S.ErrorMessageText>{message}</S.ErrorMessageText>
          )}
        />
      )}
    </S.Container>
  );
};

export default React.forwardRef<HTMLInputElement, T.InputProps>(Input);
