import { Division, DivisionDTO } from 'domain/entities/Division';

export function convertCompanyDivisionsToDivisionArray(
  companyDivisions: Record<string, DivisionDTO>
): Division[] {
  function convertDivisionDTOtoDivision(
    divisionDTO: DivisionDTO,
    id: string
  ): Division {
    return {
      ...divisionDTO,
      details: {
        ...divisionDTO.details,
      },
      id,
    };
  }
  return Object.keys(companyDivisions).map((key) =>
    convertDivisionDTOtoDivision(companyDivisions[key], key)
  );
}
