import React, { ReactElement, useMemo } from 'react';
import * as T from './types';
import * as S from './styles';
import { ReactComponent as Spinner } from './icons/spinner.svg';
import FluentIcon from '../FluentIcon/component';

const Button = (props: T.ButtonProps): ReactElement => {
  const {
    text,
    full,
    disabled,
    onClick,
    type,
    size = 'medium',
    variant = 'primary',
    icon,
    iconElement,
    onHover,
    onHoverLeave,
    id,
    ariaLabel,
    className,
    loading,
    iconPosition = 'left',
  } = props;

  const styles = {
    full,
    disabled,
    size,
    variant,
  };

  return (
    <S.Container {...styles}>
      <S.Button
        id={id}
        variant={variant}
        disabled={disabled}
        size={size}
        iconPosition={iconPosition}
        className={className}
        onClick={onClick}
        type={type}
        onMouseEnter={onHover}
        onMouseLeave={onHoverLeave}
        aria-label={ariaLabel}
      >
        {iconElement && !icon && !loading ? (
          <S.Icon>{iconElement}</S.Icon>
        ) : null}

        {!iconElement && icon && !loading ? (
          <FluentIcon
            color={
              variant === 'primary' ||
              variant === 'danger' ||
              variant === 'success'
                ? 'white'
                : 'dark'
            }
            iconName={icon}
          />
        ) : null}

        {loading ? <S.Icon>{<Spinner />}</S.Icon> : null}

        {text}
      </S.Button>
    </S.Container>
  );
};

export default Button;
