import { LinkGroup } from './types';
import IncludeLogo from '../../../assets/images/include_cloud_nav.png';
import CollapseLogo from '../../../assets/images/collapsed-include.png';

export function findLogoUrlByCollapse(
  collapse: boolean,
  clientLogo?: string,
  clientCollapsedLogo?: string
): string {
  //verify screen size whether is mobile or not
  const isMobile = window.innerWidth < 768;

  if (!collapse && !isMobile) {
    return clientLogo || IncludeLogo;
  }

  return clientCollapsedLogo || CollapseLogo;
}

type ExpandType = {
  [key: string]: boolean;
};

export function getExpandInitial(
  linkGroup: LinkGroup[]
): Record<string, boolean> {
  const key = 'sidebar-expand-initial';
  const currentInitialValue = localStorage.getItem(key);
  if (!currentInitialValue) {
    return linkGroup.reduce(
      (acc: ExpandType, group: LinkGroup) => ({
        ...acc,
        [group.title]: true,
      }),
      {}
    );
  } else {
    return JSON.parse(currentInitialValue);
  }
}
