import styled, { css } from 'styled-components';
import { FlexProps } from './types';

export const Container = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  flex: ${({ flex }) => flex};
  align-items: ${({ align }) => align || 'center'};
  justify-content: ${({ justify }) => justify || 'center'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  gap: ${({ gap }) => gap || 0}px;
  padding: ${({ padding }) => padding || 0}px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  background-color: transparent;

  ${({ bg }) =>
    bg === 'white' &&
    css`
      background-color: ${({ theme }) => theme.colors.neutral.white};
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: ${({ directionMobile }) => directionMobile};
    align-items: ${({ alignMobile }) => alignMobile};
    justify-content: ${({ justifyMobile }) => justifyMobile};
    flex: ${({ flexMobile }) => flexMobile};
  }
`;
