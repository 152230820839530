//
// This is link for the documentation in Figma:
// https://www.figma.com/file/5bOI6Vnl4D6wcXGl1KtzXS/Include-Cloud-UI-System?node-id=4%3A428
//

const light = {
  name: 'light',
  colors: {
    neutral: {
      white: '#ffffff',
      gray: '#E2E8F0',
      black: {
        100: '#F8F9FA',
        200: '#F5F5F5',
        300: '#ECECEC',
        400: '#BABABA',
        500: '#727272',
        600: '#212121',
        700: '#161616',
      },
    },
    text: {
      primary: '#212121',
      secondary: '#696969',
      disabled: '#BABABA',
      link: '#0349B3',
      title: '#171717',
    },
    borders: {
      light: '#DEE2E6',
      default: '#ABACAE',
      hover: '#696969',
      active: '#212121',
    },
    buttons: {
      primary: '#0563D6',
      hover: '#1459AF',
      active: '#1431A4',
      danger: '#B71D29',
      secondary: '#696969',
      secondaryHover: '#EDF2F7',
      disabled: '#f8f9fa',
      success: '#319B44',
    },
    status: {
      success: '#319B44',
      warning: '#FFB75D',
      danger: '#B00020',
    },
    alert: {
      error: '#F8D7DA',
      errorBorder: '#F1AEB5',
      errorText: '#842029',
      warning: '#FFF3CD',
      warningBorder: '#CC9A06',
      warningText: '#664D03',
      info: '#F9FCFF',
      infoBorder: '#012477',
      infoText: '#012477',
    },
    accent: {
      primary: {
        100: '#E1F0FF',
        200: '#69C2FD',
        300: '#44A8FB',
        400: '#0780F9',
        500: '#0563D6',
        600: '#0349B3',
        700: '#023390',
        800: '#00254D',
      },
      secondary: {
        100: '#E6F4F0',
        200: '#C4EAE3',
        300: '#AAD6D1',
        400: '#88BBBA',
        500: '#639CA0',
        600: '#447C86',
        700: '#2B5C6C',
        800: '#00221D',
      },
    },
    table: {
      selected: '#E8F1FE',
      theadBorder: '#E1E2E4',
      striped: '#F8F9FA',
    },
  },
  font: {
    size: {
      xs: '12px',
      sm: '14px',
      base: '16px',
    },
  },
  breakpoints: {
    xs: '374px',
    sm: '564px',
    md: '768px',
    lg: '1024px',
    xl: '1366px',
    xxl: '1488px',
  },
  layerindex: {
    sidebar: 9,
    overlay: 10,
    dialog: 11,
    drawer: 12,
  },
  spacings: {
    xs: 4,
    sm: 8,
    md: 12,
    lg: 24,
    xl: 32,
    xxl: 48,
    xxxl: 64,
  },
  animation: {
    default: '0.3s ease-in-out',
  },
  shadow: {
    button_secondary:
      '0px 4px 8px rgb(0 0 0 / 15%), 0px 2px 3px rgb(0 0 0 / 15%)',
    button:
      '0px 4px 8px rgba(197, 197, 197, 0.1), 0px 2px 3px rgba(10, 10, 10, 0.1);',
  },
};

export { light };
