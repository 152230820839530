import styled from 'styled-components';
import { EditorProvider } from '@tiptap/react';

export const EditorProviderr = styled(EditorProvider)`
  width: 100%;
  background-color: red;
  height: 300px;
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.neutral.white};
  border: 1px ${({ theme }) => theme.colors.neutral.black[300]} solid;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.text.primary};
  width: 100%;
  overflow: hidden;
  overflow-y: auto;

  .ProseMirror {
    background-color: transparent;
    width: 100%;
    min-height: 80px;
    max-height: 300px;
    padding: 8px;
    font-size: 0.75rem;
  }

  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.17em;
  }

  p {
    font-size: 1rem;
  }

  ul,
  ol {
    margin-left: 1rem;
  }

  ol > li {
    list-style-type: decimal !important;

    ::marker {
      font-size: 0.75rem !important;
    }
  }

  ol > li > p {
    margin-left: 4px;
  }

  ul > li {
    list-style-type: disc !important;

    ::marker {
      font-size: 0.6rem;
    }
  }

  code {
    background-color: ${({ theme }) => theme.colors.neutral.black[300]};
    padding: 8px;
    border-radius: 4px;
  }
`;
