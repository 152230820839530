import styled, { css } from 'styled-components';

interface ButtonStyleProps {
  full?: boolean;
  disabled?: boolean;
}

export const Container = styled.div<ButtonStyleProps>`
  margin: 0;
  max-width: 278px;

  ${({ full }) =>
    full &&
    css`
      max-width: unset;
      width: 100%;
    `}
`;

export const Button = styled.button<ButtonStyleProps>`
  width: auto;
  font-family: Inter;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  box-sizing: border-box;
  border-radius: 6px;
  height: 56px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  ${({ full }) =>
    full &&
    css`
      max-width: unset;
      width: 100%;
    `}

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.borders.hover};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
    border: 1px solid ${({ theme }) => theme.colors.borders.active};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xl}px;
`;
