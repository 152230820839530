import ConfigIcon from './icons/cog.svg';
import MoneyIcon from './icons/money.svg';

interface MenuLink {
  name: string;
  icon?: string;
  linkTo?: string;
  active?: boolean;
  action?(): void;
  permissions?: string[];
  tag?: string;
}

interface Group {
  title: string;
  icon?: string;
  links: MenuLink[];
  permissions: string[];
  tag?: string;
}

type Navigator = (url: string) => void;

export type ActivePage = (url: string) => unknown;

function checkSidebarPermissions(
  group: any,
  hasPermission: (permission: string) => boolean
) {
  if (Array.isArray(group.permissions)) {
    return group.permissions.some(hasPermission);
  }
  return false;
}
export const sideBarLinks = (
  navigator: Navigator,
  activePage: ActivePage,
  hasPermission: (permission: string) => boolean,
  tenantId?: string,
  companyId?: string
): Group[] => {
  return [
    {
      title: 'Include Admin',
      icon: ConfigIcon,
      permissions: [`when:include:administrator`],
      links: [
        {
          name: 'Tenants',
          active: activePage('/admin/tenants') ? true : false,
          action: () => navigator('/admin/tenants'),
          permissions: [`when:include:administrator:grant:tenants`],
        },
        {
          name: 'Companies',
          active: activePage('/admin/companies') ? true : false,
          action: () => navigator('/admin/companies'),
          permissions: [`when:include:administrator:grant:companies`],
        },
        {
          name: 'Accounts',
          action: () => navigator('/admin/accounts'),
          active:
            activePage('/admin/accounts') &&
            !activePage('/admin/companies') &&
            !activePage('/admin/company/settings')
              ? true
              : false,
          permissions: [`when:include:administrator:grant:accounts`],
        },
        {
          name: 'Roles',
          action: () => navigator('/admin/settings/roles'),
          active:
            activePage('/admin/settings/roles') && !activePage('/admin/roles')
              ? true
              : false,
          permissions: [`when:include:administrator:grant:roles`],
        },
        {
          name: 'Activity',
          action: () => navigator('/admin/activity'),
          active: activePage('/admin/activity') ? true : false,
          permissions: [`when:include:administrator:grant:activity`],
        },
      ].filter((link) => checkSidebarPermissions(link, hasPermission)),
    },
    {
      title: 'Administration',
      icon: ConfigIcon,
      permissions: [
        `when:companies:${companyId}:grant:companies:all`,
        `when:companies:${companyId}:grant:companies:update`,
        `when:tenants:${tenantId}:grant:tenants:all`,
        `when:tenants:${tenantId}:grant:tenants:update`,
        `when:include:administrator`,
      ],
      links: [
        {
          name: 'Tenants Settings',
          action: () => navigator('/admin/tenant/settings'),
          active: activePage('/admin/tenant/settings') ? true : false,
          permissions: [
            `when:tenants:${tenantId}`,
            `when:include:administrator`,
          ],
        },
        {
          name: 'Company Settings',
          action: () => navigator('/admin/company/settings/general'),
          active: activePage('/admin/company/settings') ? true : false,
          permissions: [
            `when:companies:${companyId}`,
            `when:include:administrator`,
          ],
        },
        {
          name: 'Members',
          action: () => navigator('/admin/members'),
          active:
            activePage('/admin/members') &&
            !activePage('/admin/members/groups') &&
            !activePage('/admin/tenant/settings')
              ? true
              : false,
          permissions: [
            `when:tenants:${tenantId}`,
            `when:companies:${companyId}`,
            `when:include:administrator`,
          ],
        },
      ].filter((link) => checkSidebarPermissions(link, hasPermission)),
    },
    {
      title: 'Payments',
      icon: MoneyIcon,
      permissions: [''],
      links: [
        {
          name: 'Invoices',
          action: () => navigator('/payments/invoices'),
          active: activePage('/payments/invoices') ? true : false,
        },
        {
          name: 'Transactions',
          action: () => navigator('/payments/transactions'),
          active: activePage('/payments/transactions') ? true : false,
        },
        {
          name: 'Clients',
          action: () => navigator('/payments/clients'),
          active: activePage('/payments/clients') ? true : false,
        },
      ],
    },
    {
      title: 'Production',
      tag: 'Beta',
      icon: ConfigIcon,
      permissions: [
        `when:companies:${companyId}:grant:teams:all`,
        `when:companies:${companyId}:grant:teams:read:`,
      ],
      links: [
        {
          name: 'Tasks',
          action: () => navigator('/go/tasks'),
          active: activePage('/go/tasks') ? true : false,
        },
        {
          name: 'Scheduler',
          action: () => navigator('/go/scheduler'),
          active: activePage('/go/scheduler') ? true : false,
        },
        {
          name: 'Projects',
          action: () => navigator('/go/projects'),
          active: activePage('/go/projects') ? true : false,
        },
        {
          name: 'Teams',
          action: () => navigator('/go/teams'),
          active: activePage('/go/teams') ? true : false,
        },
        {
          name: 'Maps',
          action: () => navigator('/go/maps'),
          active: activePage('/go/maps') ? true : false,
          tag: 'Alpha',
        },
      ],
    },
  ].filter((group: any) => checkSidebarPermissions(group, hasPermission));
};
