import styled, { css } from 'styled-components';

export const Root = styled.div`
  position: relative;
  width: fit-content;
  z-index: 1;
`;

export const ClearButton = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 4px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  box-shadow: 0px 4px 8px rgba(71, 71, 71, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  :hover {
    border-color: ${({ theme }) => theme.colors.borders.hover};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  }

  & div {
    display: flex;
    align-items: center;
  }
`;

export const Container = styled.label<{ width?: string; circle?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  height: 143px;
  background: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ circle }) => (circle ? '50%' : '4px')};
  position: relative;
  box-shadow: 0px 4px 8px rgba(71, 71, 71, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.1);

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  :hover {
    border-color: ${({ theme }) => theme.colors.accent.primary[500]};
  }

  ${({ circle }) =>
    circle &&
    css`
      height: 131px;
      width: 131px;
      overflow: hidden;
    `}
`;

export const ImageWrapper = styled.div<{ circle?: boolean }>`
  position: absolute;
  top: 16px;
  width: 70%;
  height: 60%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ circle }) =>
    circle &&
    css`
      top: 0px;
      width: 100%;
      height: 100%;
    `}
`;

export const Input = styled.input`
  background-color: transparent;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 12px;
  gap: 8px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const Title = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.neutral.black[600]};
`;

export const Description = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.neutral.black[500]};
`;

export const ButtonWrapper = styled.div<{ hidden?: boolean }>`
  position: absolute;
  top: 75%;
  visibility: visible;
  opacity: 1;

  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`;

export const Img = styled.img<{ circle?: boolean }>`
  width: 100%;
`;
