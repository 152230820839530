import styled, { css } from 'styled-components';
import * as Tabs from '@radix-ui/react-tabs';

export const Container = styled(Tabs.Root)`
  display: flex;
  flex-direction: column;

  &[data-orientation='vertical'] {
    flex-direction: row;
  }

  width: 100%;
`;

export const List = styled(Tabs.List)<{ gap?: number }>`
  display: flex;

  &[data-orientation='vertical'] {
    flex-direction: column;
  }
`;

export const Trigger = styled(Tabs.Trigger)``;

export const TabButton = styled.button<{ className?: string }>`
  font-family: 'Inter';
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.sm}px 1rem;
  font-weight: 500;
  font-size: 1rem;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.neutral.black[600]};
  cursor: pointer;
  border-radius: 4px 4px 0px 0px;
  line-height: 1rem;
  white-space: nowrap;
  gap: ${({ theme }) => theme.spacings.sm}px;

  & svg {
    path {
      stroke: ${({ theme }) => theme.colors.neutral.gray[600]};
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};

    ::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15%;
      height: 4px;
      width: 70%;
      border-radius: 4px 4px 0px 0px;
      ${({ className }) =>
        !className?.includes('active') &&
        css`
          background-color: ${({ theme }) => theme.colors.borders.default};
        `}
    }
  }

  ${({ className }) =>
    className?.includes('active') &&
    css`
      color: ${({ theme }) => theme.colors.accent.secondary[700]};
      border-color: ${({ theme }) => theme.colors.text.primary};

      ${({ theme }) => theme.colors.accent.primary[600]};
      & svg {
        path {
          stroke: ${({ theme }) => theme.colors.accent.primary[600]};
        }
      }

      ::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 15%;
        height: 4px;
        width: 70%;
        border-radius: 4px 4px 0px 0px;
        background-color: ${({ theme }) => theme.colors.accent.secondary[700]};
      }
    `}

  > div {
    height: 16px;
  }
`;

export const ButtonText = styled.span<{ className?: string }>``;

export const WrapTrigger = styled.div`
  display: flex;
  align-items: center;
`;
export const Content = styled(Tabs.Content)`
  width: 100%;
`;
