import {
  HttpRequest,
  HttpResponse,
  HttpClient,
} from 'data/protocols/http/HttpClient';

import axios, { AxiosResponse } from 'axios';
export class AxiosProvider implements HttpClient {
  constructor(private readonly accessToken?: string) {}

  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse;

    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: this.accessToken
          ? {
              Authorization: `Bearer ${this.accessToken}`,
              ...data.headers,
            }
          : data.headers,
      });
    } catch (error) {
      axiosResponse = error.response;
    }
    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data,
    };
  }
}
