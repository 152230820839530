import styled, { css } from 'styled-components';
import { IconColor } from './types';

type ContainerProps = {
  color?: IconColor;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  & div {
    display: flex;
    align-items: center;
  }

  & svg {
    ${({ color }) =>
      color === 'blue' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.accent.primary[500]};
        }
      `}

    ${({ color }) =>
      color === 'gray' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.neutral.black[200]};
        }
      `}
      

    ${({ color }) =>
      color === 'dark' &&
      css`
        path {
          fill: ${({ theme }) => theme.colors.neutral.black[500]};
        }
      `}
  }
`;
