import styled, { css } from 'styled-components';
import { ButtonSize, ButtonVariant } from './types';

interface ButtonStyleProps {
  full?: boolean;
  disabled?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  iconPosition?: 'left' | 'right';
}

export const Container = styled.div<ButtonStyleProps>`
  max-width: fit-content;

  ${({ full }) =>
    full &&
    css`
      max-width: unset;
      width: 100%;
    `}
`;

export const Button = styled.button<ButtonStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  gap: 0.5rem;
  line-height: 1;

  ${({ iconPosition }) =>
    iconPosition === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
  ${({ variant }) =>
    variant === 'primary' &&
    css`
      background-color: ${({ theme }) => theme.colors.buttons.primary};
      color: ${({ theme }) => theme.colors.neutral.white};

      :hover {
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
          0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      }

      :active {
        box-shadow: none;
      }
    `}
    ${({ variant }) =>
    variant === 'danger' &&
    css`
      background-color: ${({ theme }) => theme.colors.buttons.danger};
      color: ${({ theme }) => theme.colors.neutral.white};

      :hover {
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
          0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      }

      :active {
        box-shadow: none;
      }
    `}
    ${({ variant }) =>
    variant === 'success' &&
    css`
      background-color: ${({ theme }) => theme.colors.buttons.success};
      color: ${({ theme }) => theme.colors.neutral.white};

      :hover {
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
          0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      }

      :active {
        box-shadow: none;
      }
    `}
    ${({ variant }) =>
    variant === 'outline' &&
    css`
      background-color: ${({ theme }) => theme.colors.neutral.white};
      color: ${({ theme }) => theme.colors.text.primary};
      border: 1px solid ${({ theme }) => theme.colors.buttons.secondary};

      :hover {
        background-color: var(--Opacity-Gray-1, rgba(33, 33, 33, 0.08));
      }

      ${({ disabled }) =>
        disabled &&
        css`
          border-color: #e1e2e4;
        `}
    `}
    ${({ variant }) =>
    variant === 'ghost' &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.colors.text.primary};

      :hover {
        background-color: var(--Opacity-Gray-1, rgba(33, 33, 33, 0.08));
      }

      ${({ disabled }) =>
        disabled &&
        css`
          background-color: transparent !important;
          color: #bababa !important;
        `}
    `}
    ${({ size }) =>
    size === 'small' &&
    css`
      padding: 0 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      height: 24px;
    `}
    ${({ size }) =>
    size === 'medium' &&
    css`
      padding: 0 1rem;
      font-size: 0.875rem;
      font-weight: 600;
      height: 32px;
    `}
    ${({ size }) =>
    size === 'large' &&
    css`
      padding: 0 1rem;
      font-size: 1rem;
      font-weight: 600;
      height: 40px;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      color: #bababa;
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.colors.buttons.disabled};

      :hover {
        box-shadow: none;
      }
    `}
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;

  & div {
    display: flex;
    align-items: center;
  }
`;
