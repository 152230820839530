import React, { ReactElement } from 'react';
import Flex from 'components/common/Flex';
import FilterPill from 'components/common/FilterPill';
import * as T from './types';
import * as S from './styles';

const Filter = (props: T.TemplateFilterProps): ReactElement => {
  const {
    clearAllFilters,
    appliedFilters,
    displayedFilters,
    onQueryClear,
    hasSaveButton,
    onSave,
  } = props;

  return (
    <S.Container>
      <Flex fullWidth align="flex-start" gap={12} direction="column">
        <Flex direction="row" wrap="wrap" justify="flex-start" gap={8}>
          {displayedFilters.map((displayedFilter) => (
            <Flex key={displayedFilter.key}>{displayedFilter.component}</Flex>
          ))}
        </Flex>

        <Flex fullWidth justify="flex-start">
          <FilterPill
            hasSaveButton={hasSaveButton}
            onSave={onSave}
            clearAllFilters={clearAllFilters}
            filters={appliedFilters}
            removeFilter={onQueryClear}
          />
        </Flex>
      </Flex>
    </S.Container>
  );
};

export default Filter;
