import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.xs}px;
`;

export const Label = styled.label<{ color?: string }>`
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: ${({ theme, color }) => color ?? theme.colors.text.primary};
`;
