import React, { ReactElement, useEffect, useState } from 'react';
import * as T from './types';
import * as S from './styles';
import { Button, Flex, Input, PopOver } from 'components';
import { DateRange, DayPicker } from 'react-day-picker';
import FluentIcon from 'components/common/FluentIcon/component';
import 'react-day-picker/dist/style.css';
import './daypicker.css';
import { pressEscKey } from 'helper/press-esc';

export const DatePickerRange = (
  props: T.DatePickerRangeProps
): ReactElement => {
  const {
    onChange,
    label,
    placeholder,
    defaultOpen,
    defaultEndDate,
    defaultStartDate,
    onDone,
  } = props;
  const [range, setRange] = useState<DateRange | undefined>();
  const [error, setError] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [startMonth, setStartMonth] = useState<Date | undefined>(
    defaultStartDate || new Date()
  );
  const [endMonth, setEndMonth] = useState<Date | undefined>(
    startMonth?.getMonth() === (defaultEndDate || new Date()).getMonth()
      ? addMonths(defaultEndDate || new Date(), 1)
      : defaultEndDate || new Date()
  );

  function addMonths(date: Date, months: number) {
    const copy = new Date(date);
    copy.setMonth(date.getMonth() + months);
    return copy;
  }

  useEffect(() => {
    if (defaultStartDate || defaultEndDate) {
      setRange({
        from: defaultStartDate,
        to: defaultEndDate,
      });
    }
  }, [defaultStartDate, defaultEndDate]);

  useEffect(() => {
    if (range && !error) {
      if (range?.from || range?.to) {
        onChange?.(range.from, range.to);
      }
    }
  }, [range]);

  useEffect(() => {
    const startDate = range?.from;
    const endDate = range?.to;

    // Format the date when dates change
    if (startDate || endDate) {
      const startStr = startDate?.toLocaleDateString() || '--/--/----';
      const endStr = endDate?.toLocaleDateString() || '--/--/----';
      setInputValue(`${startStr} - ${endStr}`);
    }
  }, [range]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    const dates = value.split('-').map((date) => date.trim());
    if (dates.length === 2) {
      const parsedStart = new Date(dates[0]).getTime();
      const parsedEnd = new Date(dates[1]).getTime();

      if (
        !isNaN(parsedStart) &&
        !isNaN(parsedEnd) &&
        parsedStart <= parsedEnd
      ) {
        const newRange = {
          from: new Date(dates[0]),
          to: new Date(dates[1]),
        };
        setRange(newRange);

        setStartMonth(newRange.from);

        if (newRange.to.getMonth() === newRange.from.getMonth()) {
          setEndMonth(addMonths(newRange.to, 1));
        } else {
          setEndMonth(newRange.to);
        }
      }
    }
  };

  useEffect(() => {
    const regex = new RegExp(
      '^(\\d{1,2}/\\d{1,2}/\\d{4}|--/--/----) - (\\d{1,2}/\\d{1,2}/\\d{4}|--/--/----)$'
    );

    if (inputValue && !regex.test(inputValue)) {
      setError(true);
    } else {
      const startDate = inputValue?.split('-')[0]?.trim();
      const endDate = inputValue?.split('-')[1]?.trim();

      if (startDate) {
        setRange({
          from: new Date(startDate),
          to: range?.to,
        });
        setStartMonth(new Date(startDate));
      }

      if (endDate) {
        setRange({
          from: range?.from,
          to: new Date(endDate),
        });

        if (new Date(endDate).getMonth() === startMonth?.getMonth()) {
          setEndMonth(addMonths(new Date(endDate), 1));
        } else {
          setEndMonth(new Date(endDate));
        }
      }
      setError(false);
    }
  }, [inputValue]);

  return (
    <PopOver
      defaultOpen={defaultOpen}
      align="start"
      preventActiveTriggerStyles
      triggerFullWidth
      trigger={
        <S.Trigger>
          <Input
            label={label}
            fullWidth
            iconElement={<FluentIcon iconName="CalendarLtr20Regular" />}
            name="dateRange"
            placeholder={placeholder || ''}
            value={inputValue}
            onChange={handleInputChange}
            errors={error ? { dateRange: error } : {}}
          />
        </S.Trigger>
      }
      noWrap
      content={
        <S.Content className="daypicker">
          <Flex
            className="daypicker"
            direction="column"
            align="flex-end"
            fullWidth
          >
            <Flex fullWidth justify="flex-start" className="daypicker">
              <DayPicker
                month={startMonth}
                defaultMonth={range?.from || defaultStartDate || new Date()}
                mode="range"
                selected={range}
                onSelect={setRange}
                onMonthChange={(month) => setStartMonth(month)}
              />

              <DayPicker
                month={endMonth}
                defaultMonth={addMonths(
                  range?.to || defaultEndDate || new Date(),
                  1
                )}
                mode="range"
                selected={range}
                onSelect={setRange}
                onMonthChange={(month) => {
                  if (month.getMonth() === startMonth?.getMonth()) {
                    setEndMonth(addMonths(month, 1));
                  } else {
                    setEndMonth(month);
                  }
                }}
              />
            </Flex>

            <Flex gap={8}>
              <S.ClearButton
                className="clear-button"
                onClick={() => {
                  setRange(undefined);
                  setInputValue('');
                  onChange?.(undefined, undefined);
                }}
              >
                Clear
              </S.ClearButton>

              <Button
                text="Done"
                size="small"
                variant="outline"
                onClick={() => {
                  onDone?.();
                  pressEscKey();
                }}
              />
            </Flex>
          </Flex>
        </S.Content>
      }
    />
  );
};
