import styled, { css } from 'styled-components';

export const Container = styled.div<{ active?: boolean }>`
  position: relative;
  width: fit-content;
  height: 40px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 12px;

  :hover {
    border-color: ${({ theme }) => theme.colors.neutral.black[300]};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.accent.primary[800]};
      }
    }
  }

  ${({ active }) =>
    active &&
    css`
      border-color: ${({ theme }) => theme.colors.neutral.black[300]};

      svg {
        path {
          fill: ${({ theme }) => theme.colors.accent.primary[800]} !important;
        }
      }
    `}
`;

export const Action = styled.button<{
  active?: boolean;
  mobileHidden?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 40px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid transparent;

  ${({ mobileHidden }) =>
    mobileHidden &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        display: none;
      }
    `}

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
    border: 1px solid ${({ theme }) => theme.colors.neutral.black[400]};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  }

  svg {
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.colors.neutral.black[400]};
    }
  }

  position: relative;
`;

export const ExtraActionsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  top: 125%;
  right: 0;
  max-width: 190px;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid rgba(171, 172, 174, 0.2);
  box-shadow: 1px 8px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`;

export const ExtraActionItem = styled.button<{ isWarning?: boolean }>`
  font-family: 'Inter';
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 40px;
  width: 100%;
  padding: 0 8px;
  cursor: pointer;
  color: ${({ theme, isWarning = false }) =>
    isWarning ? theme.colors.status.danger : theme.colors.accent.primary[800]};
  font-weight: 500;
  font-size: 1rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.black[200]};
  }

  :disabled {
    color: ${({ theme }) => theme.colors.neutral.black[400]};
    cursor: not-allowed;

    & svg {
      path {
        fill: ${({ theme }) => theme.colors.neutral.black[400]} !important;
      }
    }
  }
`;
